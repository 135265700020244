define('localise_light',["gusto-core", "jquery", "modal", "underscore", "cookies", "domready!"], function(gusto, $, modal, _, cookies)
{
    "use strict";

    var COOKIE_STATION_MAX_AGE_DAYS = gusto.SETTINGS.COOKIE_STATION_MAX_AGE_SECONDS / 60 / 60 / 24;

    var localise_light = {
        ukrp_endpoints: null,
        init: function () {
            if (gusto.PLAYER_INFO.localisation_light_enabled) {
                this.ukrp_endpoints = gusto.PLAYER_INFO.ukrp_endpoints;
                if (cookies.readCookie('station')) {
                    var slug = cookies.readCookie('station');
                    this.setup_player_info(slug);
                }
            }
        },

        show_modal: function (twin_station, callback) {
            var that = this;
            if (cookies.readCookie('station')) {
                var cookie_val = cookies.readCookie('station');
                this.setup_player_info(cookie_val, callback);
            } else {
                var modal_details = gusto.PLAYER_INFO.modal_details;
                var modal_title = modal_details.modal_title;
                var modal_content = modal_details.modal_content;

                var template = _.template(
                    "<div class='modal__header'>" +
                        "<h2 id='dialog_label' class='modal__heading'><%= modal_title %></h2>" +
                        "<p><%= modal_content %></p>" +
                    "</div>" +
                    "<div class='modal__list localisation__list'>" +
                        "<% _.each(ukrp_endpoints, function(obj) { %>" +
                        "<a href='/' class='modal__link localisation__item' data-slug='<%= obj.slug %>' data-endpoint='<%= obj.streaming_endpoint %>'><%= obj.label %>" +
                        "<% }) %>" +
                    "</div>"
                );

                var endpoints = that.ukrp_endpoints;
                if (twin_station) {
                    endpoints = that.ukrp_endpoints.filter(function(ukrp_endpoint) {
                        return gusto.PLAYER_INFO.twin_stations[twin_station].endpoints.indexOf(ukrp_endpoint.streaming_endpoint) !== -1;
                    })
                    modal_content = modal_content.replace('[STATION]', gusto.PLAYER_INFO.twin_stations[twin_station].station_name);
                }
                else {
                    modal_content = modal_content.replace('[STATION]', gusto.STATION.name);
                }

                var modalInstance = modal.open({
                    modalClass: 'localisation_light_modal',
                    html: $(template({
                        modal_title: modal_title,
                        modal_content: modal_content,
                        ukrp_endpoints: endpoints
                    }))
                });

                var modalContents = modalInstance.get_modal_contents();

                modalContents.find('.localisation__item[data-slug]').on('click', that.get_station_bind(callback));
            }
        },

        get_station_bind: function (callback) {
            var that = this;
            return function () {
                var slug = $(this).data('slug');
                var endpoint = $(this).data('endpoint');
                cookies.createCookie('station', slug, COOKIE_STATION_MAX_AGE_DAYS);
                that.setup_player_info(slug, callback, endpoint);
                return true;
            };
        },

        setup_player_info: function (slug, callback, endpoint) {
            var player_url = null;

            var player = gusto.PLAYER_INFO.ukrp_endpoints.filter(function (ukrp_endpoint) {
                if (endpoint) {
                    return ukrp_endpoint.slug === slug && ukrp_endpoint.streaming_endpoint === endpoint;
                }
                return ukrp_endpoint.slug === slug;
            })[0];

            var ukrp_url = player.ukrp_url

            if (gusto.PLAYER_INFO.global_player_enabled) {
                player_url = player.global_player_url;
            }
            gusto.PLAYER_INFO.is_localised = true;
            gusto.PLAYER_INFO.localised_slug = slug;
            gusto.PLAYER_INFO.player_url = player_url;
            gusto.PLAYER_INFO.player_url_ukrp = ukrp_url;

            if (callback) {
                callback(player_url, ukrp_url);
            }

            return true;
        }
    };

    localise_light.init();
    return localise_light;
});

