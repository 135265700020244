(function(gusto){
    'use strict';

    require.config({
        baseUrl: gusto.STATIC_URL,
        waitSeconds: 0
    });

    require(["assets"], function(assets){

        require.config({
            paths: assets.js.page.paths,
            shim: assets.js.page.shim
        });

        require(assets.js.page.insertRequire || []);
    });

}(gusto || {}));

define("gusto/js/app/require-page.js", function(){});

