define('devices',['domready!'], function (){
    "use strict";

    var devices = {
        "ANDROID" : 1,
        "IOS" : 2,
        "UNKNOWN" : 3,
        init : function() {
            var root = document.documentElement;
            root.className += " device-" + this.get_device_type(this.os()).toLowerCase();
        },
        get_device_type : function(device_id) {
            for (var key in this){
                if (this.hasOwnProperty(key)) {
                    if (this[key] === device_id) {
                        return key;
                    }
                }
            }
        },
        is_mobile: function() {
            var os = this.os();
            return os === this.ANDROID || os === this.IOS;
        },
        os : function() {
            var ua = navigator.userAgent.toLowerCase();
            var device = 3;
            if (ua.indexOf("android") > -1) {
                device = 1;
            }
            if (ua.match(/ipad|iphone|ipod/)) {
                device = 2;
            }
            return device;
        },

        ua: function() {
            return navigator.userAgent.toLowerCase();
        },

        /**
         * A dictionnary of device's models
         * and unique value
         * @type {Object}
         */
        models: {
            "UNKNOWN": 0,
            "IPHONE": 1,
            "IPOD": 2,
            "IPAD": 3
        },

        /**
         * Return the value associated
         * with the current user device model
         * @return {Number}
         */
        model: function() {

            var ua = this.ua();
            var model = this.models.UNKNOWN;

            if(ua.indexOf("iphone") > -1) {
                model = this.models.IPHONE;
                return model;
            }

            if(ua.indexOf("ipod") > -1) {
                model = this.models.IPOD;
                return model;
            }

            if(ua.indexOf("ipad") > -1) {
                model = this.models.IPAD;
                return model;
            }

            return model;

        }

    };
    devices.init();
    return devices;
});

