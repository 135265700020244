define('navigation',['gusto-core', 'tracking', 'jquery', 'underscore', 'window', 'domready!'], function (gusto, tracking, $, _){
    "use strict";

    var $header = $('.header');

    var nav = {
        $nav_wrapper: $('#nav_wrapper', $header),
        $primary: $('#primary', $header),
        $primary_btn: $('.js-skip-nav', $header),
        $contextual: $('.js-contextual-nav', $header),
        $contextual_btn: $('.js-contextual-nav-toggle', $header),

        page_header_height: $('.js-header-main', $header).height(),
        primary_level: 0,
        nav_transition_time: 500,

        init: function() {
            this.init_scroll_event();
            this.init_primary_nav();
            this.init_contextual_nav();
        },

        init_scroll_event: function() {
            var that = this;

            $(window).on('scroll resize', _.throttle(function() {
                var top = $(window).scrollTop();
                if (that.$primary.is(':visible')) {
                    var new_btm = that.$primary.position().top + that.$primary.height() + that.page_header_height;
                    var view_top = top + that.page_header_height;
                    // if primary nav has been scrolled out of view, close it
                    if (new_btm < view_top) {
                        that.toggle_primary_nav(false);
                    }

                    if (gusto.breakpoints.check('large-up')) {
                        that.toggle_primary_nav(false);
                    }
                }
            }, 100));
        },

        init_primary_nav: function () {
            var that = this;

            this.update_primary_nav();

            this.$primary_btn.on('click', function() {
                that.contextual_action(false);
                if (that.$primary.is(':visible')) {
                    that.toggle_primary_nav(false);
                    window.scrollTo(0, 0);
                }
                else {
                    that.toggle_primary_nav(true);
                    window.scrollTo(0, 0);
                }

                return false;
            });

            $.each(that.$primary.find('li[data-id]'), function () {
                var $this = $(this);
                var cat_id = $this.data('id');
                if (!$this.hasClass('current')) {
                    that.init_tracking($this, {
                        category: gusto.category_tree[cat_id],
                    });
                }
                if(gusto.category_tree[cat_id].children) {
                    $this.addClass('has_children');
                }
            });

            $header.on('click', '.has_children', function (e) {
                if (gusto.breakpoints.check('medium-down')) {
                    var $this = $(this);
                    that.increment_nav_level(true);
                    var $child_nav = that.generate_primary_child_nav($this.data('id'));
                    setTimeout(function() {
                        that.animate_primary_nav();
                    }, 10);
                    that.update_primary_nav();
                    setTimeout(function () {
                        window.scrollTo(0, 0);
                        $child_nav.css('top', 0);
                        that.$nav_wrapper.removeClass('transitioning');
                    }, that.nav_transition_time);
                    e.preventDefault();
                }
            });

            $header.on('click', '.back_link a', function (e) {
                that.increment_nav_level(false);
                that.animate_primary_nav();
                that.update_primary_nav();
                window.scrollTo(0, 0);
                // Remove the leaf navigation after the animation ends
                setTimeout(function () {
                    $('nav.level-' + (that.primary_level + 1), that.$nav_wrapper).remove();
                }, that.nav_transition_time);
                e.preventDefault();
            });
        },

        init_contextual_nav: function () {
            var that = this;

            $.each(this.$contextual.find('li[data-id]'), function() {
                var $this = $(this);
                var cat_id = $this.data('id');
                that.init_tracking($this, {
                    category: gusto.category_tree[cat_id],
                });
            });

            this.$contextual_btn.on('click', function() {
                that.contextual_action();
                return false;
            });
        },

        contextual_action: function(open) {
            var cls = "contextual-nav--open";
            var $chevron = $('span', this.$contextual_btn);

            if (open === true) {
                this.$contextual.addClass(cls);
                $chevron.removeClass('chevron-down');
                $chevron.addClass('chevron-up');
            }
            else if (open === false) {
                this.$contextual.removeClass(cls);
                $chevron.addClass('chevron-down');
                $chevron.removeClass('chevron-up');
            }
            else {
                this.$contextual.toggleClass(cls);
                $chevron.toggleClass('chevron-down chevron-up');
            }
        },

        toggle_primary_nav: function(open) {
            var that = this;

            if (open) {
                $('nav', this.$nav_wrapper).addClass('open');
                that.$primary_btn.addClass('skip-nav--open');
                that.$contextual.hide();
            }
            else {
                this.reset_primary_nav();
                $('nav', this.$nav_wrapper).removeClass('open');
                that.$primary_btn.removeClass('skip-nav--open');
                that.$contextual.show();
            }
        },

        reset_primary_nav: function () {
            this.$nav_wrapper.removeClass('level-' + this.primary_level);
            this.update_x_position(this.$nav_wrapper, 0);
            this.$nav_wrapper.find('.navigation_child').remove();
            this.primary_level = 0;
        },

        update_primary_nav: function () {
            $('nav', this.$nav_wrapper).removeClass('active');
            $('nav.level-' + this.primary_level, this.$nav_wrapper).addClass('active');
        },

        animate_primary_nav: function () {
            var new_x = (this.primary_level * 100) * -1;
            if (new_x > 0){
                new_x = 0;
            }
            this.update_x_position(this.$nav_wrapper, new_x);
        },

        update_x_position: function ($el, x) {
            $el.css({
                '-webkit-transform': 'translate3d(' + x + '%, 0, 0)',
                'transform': 'translate3d(' + x + '%, 0, 0)'
            });
        },

        increment_nav_level: function (increment) {
            /*
            changes the class on the <div id="nav_wrapper">
             */
            var lvl = this.primary_level;
            this.$nav_wrapper.removeClass('level-' + lvl);

            if (increment) {
                lvl += 1;
            } else {
                if (lvl > 0) {
                    lvl -= 1;
                }
            }

            this.$nav_wrapper.addClass('level-' + lvl);
            this.primary_level = lvl;
        },

        build_nav_markup: function(category, children) {
            var mrkup = '<nav class="navigation_child standard open level-' + this.primary_level + '"><ul><li class="back_link"><span>' + category.name + ' <a href="#" class="nav_button"></a></span></li>';

            for (var i=0; i < children.length; i++) {
                var child = gusto.category_tree[children[i]];
                var has_children = child.children;
                var is_current = (child.full_slug === gusto.ABSOLUTE_URL);
                mrkup += '<li class="';
                if (has_children){
                    mrkup += 'has_children ';
                }
                if (is_current) {
                    mrkup += 'current';
                }
                mrkup += '" data-id="' + children[i] + '">';

                if (!has_children && is_current) {
                    mrkup += '<em>' + child.name + '</em>';
                } else {
                    mrkup += '<a href="' + child.full_slug + '">' + child.name + '</a>';
                }
                mrkup += '</li>';
            }
            mrkup += '<li class="see_all"><a href="' + category.full_slug + '">See All</a></li></ul></nav>';
            return $(mrkup);
        },

        init_tracking: function ($link, info) {
            var action, label;
            if (info.category) {
                action = info.category.name;
                if (info.subcategory) {
                    label = info.subcategory.name;
                }
            }

            $link.on('click', function() {
                var has_children = $link.hasClass('has_children');
                if (has_children && gusto.breakpoints.check('medium-down')) {
                    return;
                }
                tracking.custom('Navigation', action, label, gusto.ABSOLUTE_URL);
            });
        },

        generate_primary_child_nav: function (parent_id) {
            var that = this;
            var category = gusto.category_tree[parent_id];
            var children = category.children;
            var $child_nav = this.build_nav_markup(category, children)
                .css('top', window.scrollY + 'px');
            $.each($child_nav.find('li[data-id]'), function () {
                var $this = $(this);
                var sub_cat_id = $this.data('id');
                if (sub_cat_id) {
                    that.init_tracking($this, {
                        category: category,
                        subcategory: gusto.category_tree[sub_cat_id],
                    });
                }
            });
            this.$primary.after($child_nav);
            this.update_x_position($('nav.level-' + this.primary_level, this.$nav_wrapper), this.primary_level * 100);
            return $child_nav;
        }
    };

    nav.init();
    return nav;
});

