define('gusto/js/app/gusto.pagination',["jquery", "domready!"], function($) {
    "use strict";

    var FAKE_DELAY = 600,

    pagination = {
        init: function() {
            $('.pagination').each(function() {
                var $ui = $(this);
                var page_size = $ui.data('pagesize');

                $ui.children('.card_expander').click(function(event) {
                    var $card_expander = $(this);

                    if (!$card_expander.hasClass('loading')) {
                        $card_expander.addClass('loading');

                        setTimeout(function() {
                            $ui.siblings(".hidden").slice(0, page_size).each(function() {
                                $(this).removeClass('hidden');
                            });

                            if ($ui.siblings(".hidden").length === 0) {
                                // Everything has been displayed.
                                $ui.addClass('hidden');
                            }
                            $card_expander.removeClass('loading');

                            // Trigger smartLoad manually
                            _slm.triggerListeners();
                        }, FAKE_DELAY);
                    }

                    event.stopPropagation();
                    event.preventDefault();
                    return false;
                });
            });

            return this;
        }
    };

    return pagination.init();
});

