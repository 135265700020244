define('gusto/js/app/gusto.forms',['jquery', 'underscore', 'gusto-core', 'devices', 'tracking', 'domready!'], function ($, _, gusto, devices, tracking) {
    "use strict";

    var $show_password_wrapper = $('<div class="toggler_wrapper form_row">Show password<a href="#" id="show_password" class="toggler">&nbsp;</a></div>');
    var $show_password = $("#show_password", $show_password_wrapper);
    var $password_fields = $(".gusto_form input[type='password']");
    var value_copy_map = [
            ['#id_registration-password', '#id_registration-confirm_password'],
            ['#id_password-new_password', '#id_password-confirm_password']
        ];
    var password_fields_new_ids = '';
    var password_fields_confirm_ids = '';

    for (var i = 0; i <  value_copy_map.length; i++) {
            password_fields_new_ids += value_copy_map[i][0] + ', ';
            password_fields_confirm_ids += value_copy_map[i][1] + ', ';
        }

    var $password_field_new = $(password_fields_new_ids.replace(/, $/, ''));
    var $password_field_confirm = $(password_fields_confirm_ids.replace(/, $/, ''));
    var $password_field_confirm_row = $password_field_confirm.parent();
    var $first_error_field = $('.form_row.has_errors input, .form_row.has_errors select').first();

    if ($first_error_field) {
        $first_error_field.focus();
    }

    $password_fields.last().parent().after($show_password_wrapper);      // to avoid the error being displayed above the slider
    $password_field_confirm_row.hide();
    $password_fields.attr({"autocorrect": "off", "autocapitalize": "off"});

    $show_password.on('click', function () {

        if ($(this).hasClass('checked')) {
            $password_fields.attr('type', 'password');
            $(this).removeClass('checked');
        } else {
            $password_fields.attr("type", "text");
            $(this).addClass('checked');
        }
        return false;
    });

    $password_field_new.on('input blur', function() {
        // copy the password from one field to the other (the hidden one)
        for (var i = 0; i <  value_copy_map.length; i++) {
            $(value_copy_map[i][1]).val($(value_copy_map[i][0]).val());
        }
    });

    $('.gusto_form form').on('submit', function () {
        $password_fields.attr('type', 'password');
        if (devices.is_mobile() && devices.os != devices.UNKNOWN) {
            $('#id_reg_platform').val(devices.get_device_type(devices.os()));
        }
    });

    var gender_toggler = {
        $labels: undefined,
        $radios: undefined,

        init: function (){
            var that = this;
            this.$labels = $('.gender-choice label');
            this.$radios = $('.gender-choice input[type="radio"]');
            this.reset_labels();

            this.$radios.each(function () {
                if ($(this).is(':checked')) {
                    $(this).parent().addClass('active');
                }
            });

            this.$labels.on('click', function () {
                that.reset_labels();
                $(this).addClass('active');
            });

            this.$radios.on('focus', function () {
                that.reset_labels();
                $(this).parent().addClass('active');
            });

            this.$radios.on('blur', function () {
                if (!$(this).is(':checked')) {
                    that.reset_labels();
                }
            });
        },

        reset_labels: function () {
            this.$labels.removeClass('active');
        }
    };

    gender_toggler.init();

    var SocialConnect = function ($form, $button, provider) {
        return this.init($form, $button, provider);
    };

    SocialConnect.prototype = {
        $form: null,
        $errors_placeholder: null,
        $button: null,
        provider: null,

        errors_placeholder_selector: '.js_social_connect_errors',

        client_side_errors: {
            // If the user hits cancel.
            200001: 'Oops. Looks like you cancelled connecting socially.',
            // If the user gets a 500 from the service.
            500001: 'Looks like something went wrong when connecting. Please try again.'
        },

        response_field_map: {
            'loginProvider': 'id_provider',

            'errorCode': 'id_error_code',
            'callID': 'id_call_id',
            'regToken': 'id_reg_token',

            'isNewUser': 'id_is_new_user',
            'isRegistered': 'id_is_registered',
            'isActive': 'id_is_active',
            'isLockedOuted': 'id_is_locked_out',

            'UID': 'id_uid',
            'UIDSignature': 'id_uid_signature',
            'signatureTimestamp': 'id_signature_timestamp'
        },

        init: function($form, $button, provider) {
            this.$form = $form;
            this.$button = $button;
            this.provider = provider;
            this.$errors_placeholder = $form.find(this.errors_placeholder_selector);

            this._attach_events();
            this.$button.css('visibility', 'visible');
        },

        _attach_events: function() {
            this.$button.click(this._on_click.bind(this));
        },

        _on_click: function() {
            this._on_social_connect(this.provider);
            return false;
        },

        _on_social_connect: function(provider) {
            window.gigya.accounts.socialLogin({
                provider: provider,
                callback: this._on_gigya_login_response.bind(this)
            });
        },

        _on_gigya_login_response: function(response) {
            this.$errors_placeholder.empty();

            if (this.client_side_errors.hasOwnProperty(response.errorCode)) {
                this.$errors_placeholder.html(this.client_side_errors[response.errorCode]);
                return;
            }

            _.each(this.response_field_map, function(form_field, response_field) {
                var $field = this.$form.find('#' + form_field);
                var value = response[response_field];
                if (_.isBoolean(value)) {
                    value = value ? 1 : 0;
                }
                $field.val(value);
            }, this);
            this.$form.submit();
        }
    };

    var $social_connect_form = $('.social_connect_form');
    new SocialConnect($social_connect_form, $('.js_social_connect_facebook'), 'facebook');
    new SocialConnect($social_connect_form, $('.js_social_connect_twitter'), 'twitter');
    new SocialConnect($social_connect_form, $('.js_social_connect_apple'), 'apple');


    var register_with_email = {
        $registration_form: null,
        $toggle_button: null,

        registration_form_selector: '.vip_register .form_section',
        toggle_button_selector: '.js_register_form_toggle',
        // Feature flag around social sign on. Will be stripped on completion.
        social_sign_on_flag_class: 'social_sign_on',
        mobile_header_selector: '#header_wrapper',

        toggle_class: 'is_visible',
        scroll_duration: 600,

        init: function() {
            this.$registration_form = $(this.registration_form_selector);
            this.$toggle_button = $(this.toggle_button_selector);

            if (!this.$registration_form.hasClass(this.social_sign_on_flag_class)) {
                return;
            }

            if (this.$registration_form.hasClass(this.toggle_class)) {
                this.scroll_to_form();
                // If we added the class server side, ensure that the button matches.
                this.$toggle_button.addClass(this.toggle_button_selector);
            }

            this._attach_events();
            this.$toggle_button.css('visibility', 'visible');
        },

        _attach_events: function() {
            this.$toggle_button.click(this._on_click.bind(this));
        },

        _on_click: function() {
            this.$registration_form.toggleClass(this.toggle_class);
            this.$toggle_button.toggleClass(this.toggle_class);

            if (this.$registration_form.hasClass(this.toggle_class)) {
                this.scroll_to_form();
            }
            return false;
        },

        scroll_to_form: function() {
            // We need to take into account the fixed header at the mobile breakpoint
            var offset = gusto.breakpoints.check('medium-down') ? $("#header").outerHeight() : 0;

            $('html, body').animate({
                // Offset by the header wrapper.
                scrollTop: this.$registration_form.offset().top - offset
            }, this.scroll_duration);
        }
    };

    register_with_email.init();

    // sign-in / registration tracking
    if (gusto.tracking_registration_done) {
        tracking.custom('Account', 'Signup');
    } else if (gusto.udb_user.grid && !sessionStorage.last_logged_in_state) {
        tracking.custom('Account', 'Login');
    }

    return {
        register_with_email: register_with_email,
        SocialConnect: SocialConnect,
        gender_toggler: gender_toggler,
    };
});

