define('app_download',['window', 'jquery', 'announcement', 'gusto-core', 'domready!'], function (window, $, announcement, gusto){
    'use strict';

    var app_download = {
        showAnnouncement: function(onListenCallback) {
            var body = [];

            body.push({'class': 'announcement__msg', 'text': 'You need to use the Global Player app to listen to live radio.'});
            body.push({'text': '<a class="announcement__app-download" target="_blank" href="' + gusto.APP_INFO.onelink_url + '">Go</a>'});

            announcement.announce(
                { 'text': gusto.SITE_NAME, 'class': 'announcement__brand-logo' },
                body
            );
            $('#player_button').removeClass('buffering');

            $('.js_announcement__listen').click(function () {
                onListenCallback($(this).data('twin-station'));
                //delay removal of overlay to prevent flash (as localise modal may appear after hiding announcement_overlay)
                window.setTimeout(function() {
                    $('.announcement').remove();
                }, 100);
                return false;
            });
        }
    };

    return app_download;
});

