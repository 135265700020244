define('a11y',['window', 'jquery'], function (window, $) {
    "use strict";

    var KEYCODE_TAB = 9;
    var isTabPressed = function(e) { return e && (e.key === 'Tab' || e.keyCode === KEYCODE_TAB) };

    var a11y = {
        focus_on_modal: function($modalElement) {
            var focusableElementsSelector = 'a[href]:not([disabled]):visible, button:not([disabled]):visible, textarea:not([disabled]):visible, input[type="text"]:not([disabled]):visible, input[type="radio"]:not([disabled]):visible, input[type="checkbox"]:not([disabled]):visible, select:not([disabled]):visible';
        
            var focusableEls = $modalElement.find(focusableElementsSelector);
            var firstFocusableEl = focusableEls[0];
            var lastFocusableEl = focusableEls[focusableEls.length - 1];

            $(firstFocusableEl).focus();
            
            $modalElement.on('keydown', function(e) {
                if (!isTabPressed(e)) return;
        
                /* get focusable Elements again, as some elements might got hidden/visible */
                focusableEls = $modalElement.find(focusableElementsSelector);
                firstFocusableEl = focusableEls[0];
                lastFocusableEl = focusableEls[focusableEls.length - 1];                
            
                if ( e.shiftKey ) /* shift + tab */ {
                    if (window.document.activeElement === firstFocusableEl) {
                        lastFocusableEl.focus();
                        e.preventDefault();
                    }
                } else /* tab */ {
                    if (window.document.activeElement === lastFocusableEl) {
                        firstFocusableEl.focus();
                        e.preventDefault();
                    }
                }
            });
        }
    }

    return a11y;
});

