define('gusto/js/app/gusto.now-playing-card',['jquery', 'listen', 'lazyload', 'domready!'], function ($, listen, lazyload) {
    "use strict";

    var mrkup_loader = {

        loaded: false,

        load: function () {
            if (!this.loaded && window.gusto.breakpoints.check('large-up')) {
                var loader = $('.now_playing_card_loader, .now_playing_card_show_only_loader');
                if (loader.length) {
                    var url = loader.attr('data-url');
                    loader.load(url , function() {
                        var $now_playing_button = $('.js_show_only_player_button');
                        listen.attach_click_event($now_playing_button);

                        // make sure the image loads are triggered
                        lazyload.loadImages($('.now_playing_card .js-lazy'));
                    });
                    this.loaded = true;
                }
            }
        }
    };

    mrkup_loader.load();
    window.addEventListener('resize', mrkup_loader.load);
});

