define('gusto/js/app/gusto.font_face_observer',['window', 'jquery', 'settings', 'smartload', 'domready!', 'font-face-observer'], function(window, $, settings){
    "use strict";

    var font_face_observer = {
        /**
         * Fired on script load, automatically fires context load for the 'main' section
         */
        init: function() {
            // Optimization for Repeat Views
            if (sessionStorage.criticalFoftDataUriFontsLoaded) {
                document.documentElement.className += " fonts-stage-2";
                return;
            }

            var fontSubsets = [];

            settings.font_faces.forEach(function(brandFont){
                fontSubsets.push(
                    new FontFaceObserver(brandFont)
                )
            })

            Promise.all([fontSubsets.forEach(function(fontSubset){fontSubset.load()})]).then(function () {
                var fontA = new FontFaceObserver(settings.font_faces[0]);

                Promise.all([fontA.load()]).then(function () {
                    document.documentElement.className += " fonts-stage-2";
        
                    // Optimization for Repeat Views
                    sessionStorage.criticalFoftDataUriFontsLoaded = true;
                });
            });
        },
    };

    font_face_observer.init();

    return font_face_observer;
});

