define('carousel',['window', 'jquery', 'gusto-core', 'slick', 'underscore', 'accordion'], function (window, $, gusto, slick, _) {
    "use strict";

    /*
        Greetings, we are using the 3rd party library of Slick JS (http://kenwheeler.github.io/slick/)
        If you feel the need to update the 3rd party code, stop now, you don't belong there.
     */

    var DEFAULT_SLIDES_SHOWN = {
        'level1': 1,
        'level2': 2,
        'level3': 3
    };

    var PORTRAIT_SLIDES_SHOWN = {
        'level1': 2,
        'level2': 4,
        'level3': 6
    };

    var carousel = {
        $cardsets: $('.cardset.scrollable'),

        init: function () {
            var that = this;
            this.$cardsets.css('visibility', 'visible');

            this.handle_cardsets();

            $(window).on('scroll resize', _.throttle(function() {
                that.handle_cardsets();
            }, 300));
        },

        handle_cardsets: function () {
            var that = this;
            var is_mobile = gusto.breakpoints.check('medium-down');
            this.$cardsets.each(function () {
                var $cardset = $(this);
                var is_featured = $cardset.hasClass('feature');

                if (is_featured && !is_mobile) {
                    // remove carousel from featured cardsets on desktop
                    that.unslick($cardset);
                } else {
                    that.init_slick($cardset);
                }
            });
        },

        init_slick: function($cardset) {
            var that = this;
            if (!this.is_initialised($cardset)) {
                $cardset.addClass('carousel').find('.scrollable__cards').each(function () {
                    var $carousel = $(this);

                    var settings = that.get_carousel_settings($cardset, $carousel);
                    jQuery.extend(settings, {
                        prevArrow: $cardset.find('.scrollable__prev'),
                        nextArrow: $cardset.find('.scrollable__next'),
                        dots: true,
                        dotsClass: 'scrollable__dots',
                        appendDots: $cardset
                    });

                    $carousel.on('init', function(event, slick) {
                        $carousel.find('.img_wrapper').addClass('visible');
                    }).slick(settings);

                    if (settings.isTopStory) {
                        $cardset.find('.article').each(function() {
                            that.init_tracking($(this), $cardset);
                        });
                    }
                });
            }
        },

        init_tracking: function($card, $cardset) {
            var $link = $card.find('a:first-child');
            var event = {
                'event': 'ga.send',
                'eventCategory': 'topStoryClick',
                'eventLabel': $card.data('streamid'),
                'eventAction': $link.attr('href'),
            };

            $link.click(function() {
                window.dataLayer.push(event);
            });
        },

        get_cardset_level: function($cardset){
            var level = 'level3';
            $.each(['level1', 'level2', 'level3'], function(i, cardset_type) {
                if ($cardset.hasClass(cardset_type)){
                    level = cardset_type;
                    return;
                }
            });
            return level;
        },

        get_slides_shown: function($cardset, card_selector){
            // The number of slides shown is determined by the level of the cardset and the format of the cards

            var level = this.get_cardset_level($cardset);
            var $card = $cardset.find(card_selector).first();

            var slides_shown = {
                'desktop' : DEFAULT_SLIDES_SHOWN[level],
                'mobile' : 1
            };

            if ($card.hasClass('portrait')){
                slides_shown.desktop = PORTRAIT_SLIDES_SHOWN[level];
                slides_shown.mobile = 2;
            }

            return slides_shown;
        },

        get_carousel_settings: function($cardset, $carousel) {
            var slides_shown = this.get_slides_shown($cardset, '.scrollable__cards > div');
            // should carousel stop scrolling (no-wrap) when the end is reached or not (wrap)
            var infinite_scroll = ($carousel.data('scrollable') === 'wrap');
            // on one click/swipe, scroll by either one card or as much cards as are visible at any given time
            var scrollby = {
                'desktop': slides_shown.desktop,
                'mobile': slides_shown.mobile
            };

            if ($carousel.data('scrollby') === 'one') {
                scrollby.desktop = 1;
                scrollby.mobile = 1;
            }

            return {
                infinite: infinite_scroll,
                slidesToShow: slides_shown.desktop,
                slidesToScroll: scrollby.desktop,
                isTopStory: $cardset.hasClass('top_story'),
                responsive: [
                    {
                        breakpoint: gusto.breakpoints.limits.medium[1],
                        settings: {
                            slidesToShow: slides_shown.mobile,
                            slidesToScroll: scrollby.mobile
                        }
                    }
                ]
            };
        },

        unslick: function($cardset) {
            if (this.is_initialised($cardset)) {
                $cardset.removeClass('carousel').find('.scrollable__cards').slick('unslick');
            }
        },

        is_initialised: function($cardset) {
            return ($cardset.find('.slick-initialized').length > 0);
        }
    };

    carousel.init();

    return carousel;
});

