define('playOverlay',["gusto-core", "navigation", "jquery", "devices", "settings", "window", "domready!"],
    function (gusto, navigation, $, devices, settings, window) {
    "use strict";

    var playOverlay = {
        $overlay: $('#playOverlay'),
        $overlayBg: $('#playOverlayBg'),
        $headerPlaybutton: $('#player_button'),

        init: function() {
            if (!this.isEnabled()) {
                this.close();
            }
        },

        isEnabled: function() {
            return devices.is_mobile()
                // disable if on a wide mobile device, like ipad pro
                && window.matchMedia('only screen and (max-width:1023px)').matches;
        },

        open: function() {
            if (!this.isEnabled()) {
                return;
            }

            var that = this;
            this.$overlayBg.show();
            this.$overlay.show();
            this.$headerPlaybutton.hide();

            // close the main nav if open
            if (navigation.$primary_btn.hasClass('skip-nav--open')) {
                navigation.toggle_primary_nav(false);
            }

            navigation.$primary_btn.addClass('skip-nav--open');
            navigation.$primary_btn.one('click', function(e) {
                that.close();
                e.stopImmediatePropagation();
            });
            // move the click handler to be the first one
            // so that the call to stopImmediatePropagation works
            var handlers = $._data(navigation.$primary_btn[0], 'events')['click'];
            var handler = handlers.pop();
            handlers.splice(0, 0, handler);
        },

        close: function() {
            this.$overlayBg.hide();
            this.$overlay.hide();
            this.$headerPlaybutton.show();
            navigation.$primary_btn.removeClass('skip-nav--open');
        },
    };

    playOverlay.init();
    return playOverlay;
});
