
define('video_placeholder',['jquery', 'fitvids', 'domready'], function($) {
    "use strict";

    var video_placeholder = {
        elem_class: '.video_placeholder',

        init: function ($context) {
            var that = this;
            that.find_and_replace($(that.elem_class, $context));
        },

        find_and_replace: function ($elements) {
            var that = this;
            $elements.each(function () {
                var $video_element = $(this);
                var replacement_html = $video_element.data('iframe-replacement') || $video_element.attr('data-iframe-replacement');

                // Re-construct video_meta from data-attributes
                var video_meta = {};
                for (var i = 0, field = ['id', 'title', 'description', 'image', 'provider']; i < field.length; i++) {
                    video_meta[field[i]] = $video_element.data('video-' + field[i]);
                }
                if (!$video_element.data('hasTracking')) {
                    // Click replacement
                    var $replacement_html = $(replacement_html);
                    $replacement_html.attr('src', decodeURI($replacement_html.attr('src')));

                    $video_element.click(function (e) {
                        $video_element = that.replace_html($video_element, $replacement_html);
                        e.preventDefault();
                        return false;
                    });
                    $video_element.data('hasTracking', true);
                };
            });
        },

        replace_html: function ($el, $replacement_html) {
            $el.replaceWith($replacement_html);
            $replacement_html.parent().fitVids({
                customSelector: " \
                iframe[src*='youtube.com'], \
                iframe[src*='youtube-nocookie.com']\
            "});
            $el.unbind();
            return $replacement_html;
        }
    };
    video_placeholder.init();
    return video_placeholder;
});

