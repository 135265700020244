define('announcement',['window', "jquery", "underscore", "a11y"], function(window, $, _, a11y) {
    "use strict";

    var announcement = {

         announce: function(headline, paragraphs) {
            /*
            headline = { 'text': 'some value', 'class': 'extra class values' }
            paragraphs = a list of objects as above
            */
            var $announce = $('.announcement');
            if ($announce.length === 0) {
                $announce = $(
                    '<div class="announcement" role="dialog" id="listen_station_dialog" aria-labelledby="dialog_label" aria-modal="true"></div>'
                ).appendTo('body');
            }
            $announce.html(
                '<h2 class="' + (headline.class || '') + '" id="dialog_label">'+ headline.text + '</h2>' +
                this._tmpl({ 'headline': headline, 'paras': paragraphs })
            );
            $('.announcement__close').click(function() {
                $('.announcement').remove();
                return false;
            });
            $('.announcement__app-download').click(function() {
                $('.announcement').remove();
                return true;
            });
            $(window.document).on('keyup', function (e) {
                //escape
                if(e.which === 27) {
                    $('.announcement').remove();
                    return false;
                }
            });
            a11y.focus_on_modal($announce);
        },

        _tmpl_el: _.template('<<%= el %><% if (classVal) { %> class="<%= classVal %>"<% } %>><%= text %></<%= el %>>'),

        _tmpl: _.template("\
                <% for (var para in paras) { %>\
                    <%= this._tmpl_el({ 'el': 'p', 'text': paras[para].text, 'classVal': (paras[para].class || '') }) %>\
                <% } %>\
                <a href='/' class='announcement__close'>Close</a>"),
    };

    return announcement;
});

