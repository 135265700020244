define('gusto/js/app/gusto.social-networks',['gusto-core', 'jquery', 'underscore', 'domready!'], function (gusto, $, _) {
    "use strict";

    var social = {
        enabled: false,
        nets: {
            'facebook': {
                'enabled': gusto.page_needs_facebook_sdk || false,
                'el': 'script',
                'el_id': 'facebook-jssdk',
                'el_src': '//connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v2.8&appId=' + gusto.FACEBOOK_APP_ID,
                'loaded': false
            },
            'twitter': {
                'enabled': gusto.page_needs_twitter_widgets || false,
                'el': 'script',
                'el_id': 'twitter-wjs',
                'el_src': '//platform.twitter.com/widgets.js',
                'loaded': false,
                'callback': function() {
                    window.twttr.events.bind('loaded', function (event) {
                        jQuery('.twitter-card iframe').attr('tabindex', '-1');
                    });
                }
            }
        },

        init: function () {
            var that = this;

            for (var net in this.nets) {
                if (that.nets[net].enabled) {
                    that.enabled = true;
                    break;
                }
            }

            if (this.enabled) {
                that.check_networks();
                var debounced_check = _.debounce(that.check_networks, 200);

                $(window).on('resize', function () {
                    debounced_check();
                });
            }
        },

        check_networks: function () {
            for (var net in social.nets) {
                if (!social.nets[net]['loaded'] && social.nets[net]['enabled']) {
                    social.create_element(social.nets[net]);
                }
            }
        },

        create_element: function (net) {
            var d = document;
            var js, sn_js = d.getElementsByTagName(net['el'])[0];
            if (d.getElementById(net['el_id'])) return;
            js = d.createElement(net['el']);
            js.id = net['el_id'];
            js.src = net['el_src'];
            js.setAttribute('async', '');
            js.onload = function() { net.callback && net.callback() };
            sn_js.parentNode.insertBefore(js, sn_js);
            net['loaded'] = true;
        }
    };

    social.init();
});
