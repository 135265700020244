define('modal',["jquery", "underscore", "a11y"], function($, _, a11y) {
    "use strict";

    var $modalEl, modalParams;
    var defaultOptions = {
        html: '',
        modalClass: '',
        backdropClass: '',
        onClose: function() {},
        onOpen: function() {},
    };

    var modal = {
        open: function(params) {
            var that = this;

            //for mobile modal that is fixed to top of screen
            window.scrollTo(0, 0);

            modalParams = $.extend({}, defaultOptions, params);
            $modalEl = $('.' + 'modal-container');

            $(window).on('resize', _.throttle(function() {
                that.reposition_modal();
            }, 500));

            if ($modalEl.length === 0) {
                $modalEl = $('<div class="modal ' + modalParams.modalClass + '" role="dialog" id="listen_station_dialog" aria-labelledby="dialog_label" aria-modal="true"><div class="modal__content"></div></div>');
                $modalEl.find('.modal__content').html(modalParams.html);
                $modalEl.show();
                $modalEl.append(this.get_close_button());
                $('body').append($modalEl);
                $('body').append(this.get_backdrop_element(modalParams.backdropClass));
                this.reposition_modal();
                a11y.focus_on_modal($modalEl);

                // Add key listener for closing modal
                $(document).on('keyup', function (e) {
                    //escape
                    if(e.which === 27) {
                        this.close_modal(modalParams.onClose);
                    }
                }.bind(this));

                modalParams.onOpen();

                return {
                    set_modal_contents: function(html) {
                        $modalEl.find('.modal__content').html(html);
                    },

                    update_position: function () {
                        this.reposition_modal();
                    }.bind(this),

                    get_modal_contents: function() {
                        return $modalEl.find('.modal__content');
                    },

                    close: function() {
                        this.close_modal(modalParams.onClose);
                    }.bind(this)
                };
            } else {
                return undefined;
            }
        },

        reposition_modal: function () {
            $modalEl.css('left', $(window).innerWidth() / 2 - $modalEl.width() / 2);
            $modalEl.css('top', Math.max($(window).innerHeight() / 2 - $modalEl.height() / 2, 0));
        },

        get_backdrop_element: function(backdropClass) {
            var $backdropEl = $('<div class="modal__overlay ' + backdropClass + '"></div>');
            $backdropEl.on('click', function() {
                this.close_modal(modalParams.onClose);
            }.bind(this));
            $backdropEl.show();
            return $backdropEl;
        },

        get_close_button: function() {
            return $('<a href="/" class="modal__close"></a>')
                .on('click', function(e) {
                    this.close_modal(modalParams.onClose);
                    e.stopPropagation();
                    e.preventDefault();
                    return false;
                }.bind(this));
        },

        close_modal: function(onCloseFn) {
            $('.modal').remove();
            $('.modal__overlay').remove();
            onCloseFn();
        }
    };

    return modal;
});

