define('tracking',['window', 'gusto-core', 'localisation-cookie', 'domready!'], function (window, gusto, localisation, _){
    "use strict";

    var Tracking = {
        custom: function (category, action, label, value) {
            if (!category) {
                console.error('Tracking error: category required for custom event.');
                return;
            }
            var custom_event = {
                'event': 'ga_event',
                'ga_event_category': category,
                'ga_event_action': action || '',
                'ga_event_label': label || '',
                'ga_event_value': value || ''
            };
            this._track(custom_event);
        },

        pageview: function(path) {
            this._track({
                'event': 'ga_virtual_pv',
                'ga_virtual_page_path': path || localisation.getLocalisedPath(),
            });
        },

        social: function(network, action, target) {
            if (!(network && action)) {
                console.error('Tracking error: missing arguments for social event.');
                return;
            }
            var social_event = {
                'event': 'socialInteraction',
                'ga_social_network': network,
                'ga_social_action': action,
                'ga_social_target': target || localisation.getLocalisedPath(),
            };
            this._track(social_event);
        },

        _track: function(event) {
            window.dataLayer.push(event);
        }
    };

    gusto.tracking = Tracking;
    return Tracking;
});
