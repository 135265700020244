define('gusto/js/app/gusto.galleries',['window', 'gusto-core', 'jquery', 
        'tracking', 'settings', 'galleries-hd', 'devices', 
        'smartload', 'domready!'], 
    function (window, gusto, $, tracking, settings, galleriesHd){
    "use strict";


    var galleries = {

        count: 0,
        current_viewed_image: {},
        init : function () {
            var that = this,
                $images = $('.gallery_image img'),
                $image_descs = $('.gallery_images .image_info');
                this.image_sharing = !!($('.gallery_images.share').length);

            if (gusto.breakpoints.check('large-up') && gusto.gallery && gusto.gallery.lightbox_enabled) {
                this.add_lightbox_open_click_listener.call(this, $images);
            }

            $images.smartLoad(function() {
                var $img = $(this);
                that.loadImage($img);
            }, { threshold: 200 });

            $image_descs.smartLoad(
                function() {
                    that.imageView($(this));
                },
                function() {
                    /*
                    unload handler is required for the repeatable
                    setting to be respected
                    */
                },
                { repeatable: true }
            );
        },

        add_lightbox_open_click_listener: function ($images) {
            var that = this;
            $images.on('click', function(e){
                var imageSrc = $(this).attr('src');
                var clickedImage = gusto.gallery.images.filter(function (image) {
                    return image.url === imageSrc;
                })[0];
                var updateUrlOnCloseFn = function () {
                    window.history.replaceState({}, clickedImage.title, clickedImage.href);
                };
                galleriesHd.show_lightbox(that.image_sharing, clickedImage.order - 1, updateUrlOnCloseFn);
                e.stopPropagation();
                e.preventDefault();
            });
        },

        loadImage: function($img) {
            var image_src = $img.data('src');
            if(image_src) {
                $img.attr('src', image_src);
                $img.removeAttr('data-src').removeData('src');
                gusto.gallery.images.filter(function (image) {
                    return image.url === image_src;
                })[0].loaded = true;
            }
        },

        imageView: function($el) {
            var path = $el.data('path');
            if (path && gusto.gallery) {
                var image = gusto.gallery.images.filter(function (image) {
                    return image.href === path;
                })[0];
                this.current_viewed_image = image;
                var title = image.title;
                var tracked = image.tracked;
                if (path && title) {
                    window.history.replaceState({}, title, path);
                    if (!tracked) {
                        tracking.pageview(path);
                        $el.data('tracked', true);
                        image.tracked = true;
                    }
                }
            }
        },

    };

    galleries.init();

    return galleries;
});

