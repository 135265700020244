define('lazyload',['window', 'jquery', 'settings', 'smartload', 'domready!'], function(window, $, settings){
    "use strict";

    var lazyload = {
        threshold: settings.lazy_load.threshold,

        /**
         * Fired on script load, automatically fires context load for the 'main' section
         */
        init: function() {
            this.init_for_context($('section[role="main"]'));
        },

        /**
         * For a particular context, lazy load the known image formats
         * @param $context Context in which to search for images to load
         */
        init_for_context: function($context) {
            var that = this;
            // Card Images
            // var $images = $('.editorial.promo .img_wrapper', $context);
            // $images.smartLoad(function () {
            //     that.loadCard(this);
            // }, {threshold: that.threshold});

            // Lead Article Image
            // var $lead_image = $context.find('.lead_image');
            // $lead_image.smartLoad(function() {
            //     that.loadImages($(this));
            // });

            // Other lazy load images
            var $lazy_images = $('.js-lazy');
            $lazy_images.smartLoad(function() {
                that.loadImages($(this));
            });
        },

        /**
         * Specific functionality for loading card images
         * @param element Card parent (required)
         * @param callback Callback Function (optional)
         */
        loadCard: function(element, callback) {
            var that = this;
            var $editorial = $(element).closest('.editorial.promo');
            var $images = $editorial.find('img');

            that.loadImages($images, callback);

            $(element).addClass('visible');
            $editorial.find('.publish_date').addClass('visible');
            $editorial.find('.topic').addClass('visible');
        },


        /**
         * Pass it an img tag $selection and it will 'lazy' load the src an fall back to the backup
         * @param $images Jquery image tag selection to load (required)
         * @param callback Function to call on each image load (optional)
         */
        loadImages: function($images, callback) {
            var that = this;

            $images.each(function(){
                var $current_image = $( this );
                var image_src = $current_image.data('src') || $current_image.data('lazy');

                that.addHandlers($current_image, callback);

                if(image_src) {
                    $current_image.attr('src', image_src)
                                  .removeAttr('data-src').removeData('src')
                                  .removeAttr('data-lazy').removeData('lazy');
                }
            });
        },


        /**
         * Util function that adds the error and load event listeners to images
         * @param $elem Image element (required)
         * @param callback  Callback function per image (optional)
         */
        addHandlers: function($elem, callback) {
            $elem.error(function() {
                $( this ).attr( "src", $( this ).data('backup'))
                         .removeAttr('data-backup').removeData('backup');
            });
            if (callback) {
                $elem.load(function() {
                    callback($elem);
                });
            }
        }
    };

    lazyload.init();

    return lazyload;
});

