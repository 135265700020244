define('gusto/js/app/gusto.dac',['window', 'gusto-core', 'settings', 'smartload', 'domready!'], function (window, gusto, settings){
    "use strict";

    var dac = {
        init: function() {
            // Load the lazy ads, if AdTech is available, otherwise return early
            this.loadAds();
        },
        loadAds: function() {
            // Initalise the lazy ads
            var ad_queue = window.gusto.lazy_ads;

            // Read UA String to allow optional logging or disabling of ad logic
            // Very useful for AT suite and debugging live
            var ua_string = window.navigator.userAgent;
            var disable_ads = ua_string.indexOf('NOGUSTOADS') > -1;
            var log_ads = ua_string.indexOf('LOGGUSTOADS') > -1;

            if(!disable_ads) {
                // Loop through the lazy ad queue
                for (var i = 0; i < ad_queue.length; i++) {
                    var ad_data = ad_queue[i];
                    var valid_breakpoint = true;
                    // Check the breakpoint
                    if(ad_data.breakpoint) {
                        valid_breakpoint = gusto.breakpoints.check(ad_data.breakpoint);
                    }
                    if(valid_breakpoint) {
                        if(log_ads) {
                            console.log('Lazy ad initialised', ad_data);
                        }

                        var $ad_container = $('#' + ad_data.id);
                        $ad_container.smartLoad(function () {
                            var $that = $(this);
                            var ad_id = $that.attr('id');
                            var ad_unit = ad_id.substr(3);
                            var ad_mapping =  window.GLOBALADVERTISING.mappings[ad_unit];

                            window.GLOBALADVERTISING.push(ad_unit, ad_mapping, true);

                            if(log_ads) {
                                console.log('Lazy ad triggered', ad_id);
                            }
                        }, {threshold: 200});
                    }
                }
            }

            // set a timer to move the banner to the top after some time
            window.WaitForIt.wait_for(['ad_banner_rendered'], function() {
                setTimeout(function() {
                    $('#banner-wrapper').removeClass('dac__banner__wrapper--sticky');
                }, gusto.ad_settings.banner_visibility_delay);
            }, gusto.ad_settings.banner_visibility_delay * 10);
        }
    };

    dac.init();

    return dac;
});

