/*
 * Standard library for getting and setting cookies.
 * Based off PPK's script: http://www.quirksmode.org/js/cookies.html
 **/

define('cookies',["domready!"], function() {
    "use strict";

    var cookies = {
        createCookie: function (name, value, days) {
            var fields = [
                name + "=" + value,
                'Path=/',
                'Secure',
                'SameSite=Strict',
            ];

            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                fields.push('Expires='+date.toGMTString());
            }

            document.cookie = fields.join(';');
        },
        readCookie: function (name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1, c.length);
                }
                if (c.indexOf(nameEQ) === 0) {
                    return c.substring(nameEQ.length, c.length);
                }
            }
            return null;
        },
        eraseCookie: function (name) {
            this.createCookie(name, "", -1);
        }
    };

    return cookies;
});

