define('gusto/js/app/gusto.schedule',["jquery", "tabs", "domready!"], function($, tabs) {
    "use strict";

    var schedule = {
        init: function() {
            var that = this;

            tabs.setupTabs({
                tabBarClass: 'tabbar schedule',
                tabSelector: 'div.day',
                maximumTabNumber: 7,
                tabCallback: function() {
                    that.load_showcard_imgs();
                }
            });

            this.init_showcard_images();
        },

        init_showcard_images: function() {
            var that = this;
            $('.schedule li.show img[data-src], .last_played_songs img').css('opacity', 0);
            that.load_showcard_imgs();
        },

        load_showcard_imgs: function() {
            /*
            We only run this on shows in the 'active' tab
            We'll re-run it when a user switches tabs
            If a user re-clicks on a tab it will be a null op
             */
            var $imgs = $('.schedule .active li.show img[data-src], .last_played_songs img');
            if ($imgs.length === 0) {
                $imgs = $('.schedule li.show img[data-src]');
            }
            $imgs.each(function() {
                var $that = $(this),
                    to_load = $that.attr('data-src'),
                    show_img = new Image();

                $that.removeAttr('data-src');
                show_img.onload = function () {
                    $that.attr('src', this.src).addClass('show_image').animate({'opacity': 1});
                };
                show_img.onerror = function () {
                    $that.css({'opacity': 1});
                };
                show_img.src = to_load;
            });
        }
    };

    schedule.init();

    return schedule;
});

