define('gusto/js/app/gusto.nowplaying',['jquery', 'domready!'], function ($){
    "use strict";

    var now_playing = {

        init: function () {
            // Handles both regular Now Playing and the "Show only" version
            var $now_playing_section = $('.now-playing__wrapper--mobile .js-now-playing-section, .now-playing__wrapper--mobile .js-show-only-card');
            var $now_playing_links = $('.now-playing__wrapper--mobile .js-now-playing-links');

            $now_playing_section.each(function (index, el) {
                // add a class to the container and append toggle link
                var $el = $(el);
                if ($now_playing_links.length === 0) return;
                var $toggle = $('<a class="now-playing__toggle js-now-playing-toggle" href="#open"></a>');
                $el.append($toggle);
            });
            $('.js-now-playing-toggle').on('click', function (e) {
                // add the open/close behaviour
                var $el = $(this);
                var href = '#close';

                if ($now_playing_links.hasClass('now-playing__links--open')) {
                    href = '#open';
                }
                $el.attr('href', href);
                $now_playing_links.toggleClass('now-playing__links--open');
                $el.toggleClass('now-playing__toggle--open');
                e.preventDefault();
            });
        }
    };

    now_playing.init();

    return now_playing;
});

