define('localise',["gusto-core", "jquery", "modal", "underscore", "localisation-cookie", "domready!"], function(gusto, $, modal, _, localisationCookie) {
    "use strict";

    var API_BASE = '/api/1.0/station_preference/v4/';
    var API_PREFERENCE = '/api/1.0/station_preference/v4/localise/';
    var modalInstance;
    var localisation = {

        init: function () {
            var that = this;
            if (this.is_page_localiser()) {
                $('nav.stations a[data-slug]').on('click', this.get_station_bind());
            }
            $('#header_localise_link').on('click', function(){
                that.show_modal();
                return false;
            });
        },

        show_modal: function(callback) {
            var that = this;
            this.api('station-list', function(data) {
                modalInstance = modal.open({html: data.html});
                var modalContents = modalInstance.get_modal_contents();
                modalContents.find('#station_choice_go').on('click', that.get_submit_bind());
                modalContents.find('a[data-slug]').on('click', that.get_station_bind(callback));
            });
        },

        get_submit_bind: function(){
            var that = this;
            return function() {
                var uk_postcode = modalInstance.get_modal_contents().find('#uk_postcode').val().trim();
                if (uk_postcode.length === 0) {
                    return false;
                }

                that.api('postcode', function(data){
                    modalInstance.set_modal_contents(data.html);
                    modalInstance.get_modal_contents().find('a[data-slug]').on('click', that.get_station_bind());
                }, {
                    'uk_postcode': uk_postcode
                });

                return false;
            };
        },

        api: function(endpoint, callback, args) {
            args = args || {};
            callback = callback || function(){};
            $.ajax(API_BASE + endpoint + '/' +
                '?redirect=' + encodeURIComponent(window.location.pathname), {
                data: args,
                dataType: 'json',
                method: {
                    'postcode': 'post',
                    'station-list': 'get'
                }[endpoint],
                success: callback,
                async: endpoint !== 'postcode'
            });
        },

        get_station_bind: function (callback) {
            var that = this;
            return function () {
                that.setup_player_info($(this).data('slug'), callback);
                that.set_localisation_preference($(this).data('slug'));
                return true;
            };
        },

        setup_player_info: function(stream_slug, callback) {
            var ukrp_url= $('[data-slug='+ stream_slug +']').data('ukrp-url')

            var player_url = null;

            if (gusto.PLAYER_INFO.global_player_enabled) {
                player_url = $('[data-slug='+ stream_slug +']').data('gp-url')
            }

            gusto.PLAYER_INFO.is_localised = true
            gusto.PLAYER_INFO.player_url = player_url;
            localisationCookie.setSessionCookie(stream_slug);

            if (callback) {
                callback(player_url, ukrp_url);
            }

            return true;
        },

        set_localisation_preference: function(station_slug) {
            var user = gusto.udb_user || {};
            if (user && user.grid){
                $.ajax(API_PREFERENCE, {
                    data: {
                        'slug': station_slug
                    },
                    dataType: 'json',
                    method: 'post',
                    async: true
                });
            }
            return true;
        },

        is_page_localiser: function(){
            return window.location.pathname === gusto.PLAYER_INFO.localise_url;
        }
    };

    localisation.init();
    window.lc = localisation; // debug
    return localisation;
});

