define('galleries-hd',['window', 'gusto-core', 'jquery', 'tracking', 'settings', 'modal', 'domready!'], function(window, gusto, $, tracking, settings, modal) {
    'use strict';

    var shareBarId = 'gigya_img_share_bar';
    var $galleryImg,
        $itemCounter,
        $shareButtons,
        $galleryPrevButton,
        $galleryNextButton,
        $galleryHtml;

    var galleriesHd = {
        init: function() {
            $galleryHtml =
                $('<div>\
                    <div class="lightbox__img_wrapper">\
                        <img class="lightbox__img" src="" alt="" />\
                        <div class="lightbox__pages"><span class="lightbox__index"></span> of <span class="lightbox__total"></span></div>\
                        <div id="' + shareBarId + '" class="lightbox__share"></div>\
                        <a class="lightbox__control lightbox__control--prev" href="#"></a>\
                        <a class="lightbox__control lightbox__control--next" href="#"></a>\
                     </div>\
                     <div class="lightbox__info">\
                         <h2></h2>\
                         <p></p>\
                     </div>\
                  </div>');
            $galleryImg = $galleryHtml.find('img');
            $itemCounter = $galleryHtml.find('.lightbox__pages');
            $shareButtons = $galleryHtml.find('#' + shareBarId);
            $galleryPrevButton = $galleryHtml.find('.lightbox__control--prev');
            $galleryNextButton = $galleryHtml.find('.lightbox__control--next');
        },

        show_lightbox: function(isImageSharingEnabled, startImageIndex, onCloseFn) {
            var clickedImage = gusto.gallery.images[startImageIndex];
            $itemCounter.find('.lightbox__total').html(gusto.gallery.image_total);
            gusto.gallery.currentIndex = startImageIndex;
            this.isImageSharingEnabled = isImageSharingEnabled;
            this.render.call(this);
            modal.open({
                onClose: function() {
                    if (onCloseFn) {
                        onCloseFn();
                    }
                    //remove key listeners
                    $(document).off('keyup');
                }.bind(this),
                modalClass: 'lightbox',
                html: $galleryHtml
            });
            this.add_portrait_class_to_lightbox_if_portrait(clickedImage);
            this.add_event_listenters();
            this.hide_or_show_arrows_if_start_or_end();
        },

        next_image: function() {
            $galleryPrevButton.show();
            if (gusto.gallery.currentIndex !== gusto.gallery.image_total - 1) {
                gusto.gallery.currentIndex++;
                this.render.call(this);
            }
        },

        previous_image: function() {
            $galleryNextButton.show();
            if (gusto.gallery.currentIndex !== 0) {
                gusto.gallery.currentIndex--;
                this.render.call(this);
            }
        },

        preload_images: function(imageIndex) {
            this.preload_image(imageIndex - 1);
            this.preload_image(imageIndex + 1);
        },

        preload_image: function(imageIndex) {
            var img = gusto.gallery.images[imageIndex];
            if (img !== undefined && !img.loaded) {
                var new_img = new window.Image();
                new_img.onload = function() {
                    img.loaded = true;
                };
                new_img.src = img.url;
            }
        },

        add_portrait_class_to_lightbox_if_portrait: function(image) {
            var isPortrait = image.height > image.width;
            if (isPortrait) {
                $('.lightbox').addClass('lightbox--portrait');
                $galleryImg.addClass('lightbox__img--portrait');
            } else {
                $('.lightbox').removeClass('lightbox--portrait');
                $galleryImg.removeClass('lightbox__img--portrait');
            }
        },

        hide_or_show_arrows_if_start_or_end: function() {
            if (gusto.gallery.currentIndex === gusto.gallery.image_total - 1) {
                $galleryNextButton.hide();
            } else {
                $galleryNextButton.show();
            }

            if (gusto.gallery.currentIndex === 0) {
                $galleryPrevButton.hide();
            } else {
                $galleryPrevButton.show();
            }
        },

        track_page_view: function(image) {
            if (!image.tracked) {
                tracking.pageview(image.href);
                image.tracked = true;
            }
        },

        fade_arrows_if_start_or_end: function() {
            if (gusto.gallery.currentIndex === gusto.gallery.image_total - 1) {
                $galleryNextButton.removeClass('lightbox__control--fade_in');
                $galleryNextButton.addClass('lightbox__control--fade_out');
            } else {
                $galleryNextButton.removeClass('lightbox__control--fade_out');
                $galleryNextButton.addClass('lightbox__control--fade_in');
            }

            if (gusto.gallery.currentIndex === 0) {
                $galleryPrevButton.removeClass('lightbox__control--fade_in');
                $galleryPrevButton.addClass('lightbox__control--fade_out');
            } else {
                $galleryPrevButton.addClass('lightbox__control--fade_in');
                $galleryPrevButton.removeClass('lightbox__control--fade_out');
            }
        },

        update_image_and_image_counter_html: function(image) {
            var addFadeOnLoadIfImageNotLoaded = function() {
                if (!image.loaded) {
                    $galleryImg.removeClass('lightbox__control--fade_in');
                    $galleryImg.addClass('lightbox__control--fade_out');
                }
                $galleryImg.one('load', function() {
                    $galleryImg.removeClass(
                        'lightbox__control--fade_out');
                    $galleryImg.addClass('lightbox__control--fade_in');
                }).each(function() {
                    if (this.complete) {
                        $(this).load();
                    }
                });
            };
            addFadeOnLoadIfImageNotLoaded();
            this.add_portrait_class_to_lightbox_if_portrait(image);
            $galleryImg.attr('src', image.url);
            $galleryImg.attr('alt', image.alt_text);
            $galleryHtml.find('h2').html(image.title);
            $galleryHtml.find('p').html(image.description);
            $itemCounter.find('.lightbox__index').html(image.order);
        },

        render: function() {
            var currentImage = gusto.gallery.images[gusto.gallery.currentIndex];
            this.update_image_and_image_counter_html(currentImage);
            this.preload_images(gusto.gallery.currentIndex);
            window.history.replaceState({}, currentImage.title, currentImage.href);
            this.track_page_view(currentImage);
            this.fade_arrows_if_start_or_end();
        },

        add_event_listenters: function() {
            $galleryPrevButton.on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
                this.previous_image();
            }.bind(this));

            $galleryNextButton.on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
                this.next_image();
            }.bind(this));

            $(document).on('keyup', function(e) {
                //left key
                if (e.which === 37) {
                    e.preventDefault();
                    e.stopPropagation();
                    galleriesHd.previous_image();
                }
                //right key
                if (e.which === 39) {
                    e.preventDefault();
                    e.stopPropagation();
                    galleriesHd.next_image();
                }
            });
        }
    };

    galleriesHd.init();
    return galleriesHd;
});

