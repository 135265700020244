define('video_embeds',['window', 'jquery', 'gusto-core', 'domready!'], function (window, $, gusto) {
    "use strict";

    var ooyala = {
        ooyala_video_class: '.js_embed_ooyala',

        init: function ($context) {
            var that = this;
            if(window.GLOBALADVERTISING && window.GLOBALADVERTISING.gdpr_consent.length == 0) {
                var checkConsentCount = 0;
                var checkConsent = setInterval(function() {
                    if (window.GLOBALADVERTISING.gdpr_consent.length > 0 && checkConsentCount < 5) {
                        that.initialize_player($(that.ooyala_video_class, $context));
                        clearInterval(checkConsent);
                    } else if(checkConsentCount >= 5) {
                        that.initialize_player($(that.ooyala_video_class, $context));
                        clearInterval(checkConsent);
                    }
                    checkConsentCount++;
                }, 1000);
            } else {
                that.initialize_player($(that.ooyala_video_class, $context));
            }
        },

        get_event_info: function(player) {
            return {
                provider: 'ooyala',
                article: gusto.article,
                title: player.getTitle(),
                id: player.getElementId(),
            }
        },

        build_config: function (video_id, ad_tag_url) {
            var that = this;
            var config = {
                "google-ima-ads-manager": {
                    "all_ads": [],
                    "useGoogleAdUI": true
                }
            }

            if (window.GLOBALADVERTISING && window.gusto.ad_switch.enable_ads_ooyala === true) {
                config['google-ima-ads-manager']['all_ads'] = [
                    {
                        "position": "0", // Time that the ad will play, in this case preroll ad runs at 0ms.
                        "position_type": "t",
                        "tag_url": ad_tag_url
                    }
                ];
            }

            return config;
        },

        initialize_player: function ($elements) {
            var that = this;
            $elements.each(function () {
                var $video_element = $(this);
                var video_id = $video_element.data('id');
                var isNonPersonalisedAds = gusto.sourcepoint.checkIabVendorConsentById(gusto.sourcepoint.iabIds.vendors.google) ? 0 : 1;
                var ad_tag_url = gusto.ooyala.player_google_ad_tag +
                gusto.PROJECT_NAME + "/" +
                "video/video_player&description_url=" + encodeURIComponent(gusto.ROOT_URL) +
                "&env=vp&impl=s&correlator=" + Math.floor(Math.random() * Math.pow(10, 15)) +
                "&tfcd=0&npa=" + isNonPersonalisedAds + "&gdfp_req=1&output=vast&sz=400x300|640x480&url=" + window.location.href;

                if(window.gusto.ooyala !== undefined && window.GLOBALADVERTISING) {
                    ad_tag_url += "&cust_params=" +
                        encodeURIComponent("kvplayer=" + "ooyala") +
                        encodeURIComponent("&pageid=" + window.GLOBALADVERTISING.pageid) +
                        encodeURIComponent("&local=" + window.GLOBALADVERTISING.local) +
                        encodeURIComponent("&adtest=" + window.GLOBALADVERTISING.adtest) +
                        encodeURIComponent("&section=" + gusto.CATEGORY_PATH) +
                        encodeURIComponent("&at=" + window.GLOBALADVERTISING.at) +
                        encodeURIComponent("&sensitive=" + window.GLOBALADVERTISING.sensitive) +
                        encodeURIComponent("&gdpr=" + window.GLOBALADVERTISING.gdpr) +
                        encodeURIComponent("&gdpr_pd=" + window.GLOBALADVERTISING.gdpr_pd) +
                        encodeURIComponent("&gdpr_consent=" + window.GLOBALADVERTISING.gdpr_consent) +
                        encodeURIComponent("&nmc=" + gusto.nielsen.segments) +
                        "&unviewed_position_start=1";
                }
                window.OO.ready(function () {
                    var config = that.build_config(video_id, ad_tag_url);
                    window.pp = window.OO.Player.create('ooyala_' + video_id, video_id, config);
                });
            });
        }
    };
    if (!gusto.ooyala) {
        gusto.ooyala = {};
    }

    // race condition mitigation between ooyala player and our own javascript
    gusto.ooyala.requireIsReady = true;

    if (gusto.ooyala.ooyalaIsReady || window.avocado) {
        ooyala.init();
    }
    gusto.ooyala.initOoyalaVideos = function() {
        ooyala.init();
    };

    return ooyala;
});

