define('gusto/js/app/gusto.trending',['window', 'jquery'], function (window, $) {
    "use strict";
    var isSpacePressed = function(e) {
        return e && ( e.key === ' ' || e.key === 'Space' || e.key === 'Spacebar')
    };

    var trending = {
        setup_dropdown: function() {
            var $expandButton = $('.js-trending-bar-toggle');
            $expandButton.on('click keypress', function(e) {
                if (e.type === 'keypress' && !isSpacePressed(e)) return;
                $(this).toggleClass('trending_bar__expand-button--open');
                $('.trending_bar__links').toggleClass('trending_bar__links--open');
                e.preventDefault();
            });
        }
    }

    trending.setup_dropdown();

    return trending;
});

