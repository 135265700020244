define('gusto/js/app/gusto.editorial',['jquery', 'domready!'], function ($) {
    'use strict';

    var editorial_expander = {

        slide_speed: 400,  // ms

        state: {
            hidden: 'is-hidden',
            visible: 'is-visible'
        },

        selectors: {
            container: '.editorial.expanding-form',  // What wraps everything.
            toggle: 'a[data-action="toggle"]',  // What we click to toggle it.
            toggled_item: '.expanded'  // What we show/hide.
        },

        toggle_text: {
            hidden: "View more",
            visible: "View less"
        },

        init: function () {
            var $toggle = $(this.selectors.container).find(this.selectors.toggle);
            $toggle.on('click', this._on_click.bind(this));
        },

        _on_click: function (event) {
            event.preventDefault();

            var $toggle = $(event.currentTarget);
            var $expanded = $toggle.siblings(this.selectors.toggled_item);

            if ($toggle.hasClass(this.state.hidden)) {
                this._show($expanded, $toggle);
            } else {
                this._hide($expanded, $toggle);
            }
        },

        _show: function ($expanded, $toggle) {
            $expanded.slideDown(this.slide_speed, (function () {
                $toggle.toggleClass(this.state.visible + ' ' + this.state.hidden);
                $toggle.html(this.toggle_text.visible);
            }).bind(this));
        },

        _hide: function ($expanded, $toggle) {
            $expanded.slideUp(this.slide_speed, (function () {
                $toggle.toggleClass(this.state.visible + ' ' + this.state.hidden);
                $toggle.html(this.toggle_text.hidden);
            }).bind(this));
        }
    };

    editorial_expander.init();
    return editorial_expander;
});

