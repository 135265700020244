/* Tabs support for v4, takes facet Cardsets (<div class="facet">)
 * and creates a tab bar to select between them.
 */
define('tabs',['window', 'jquery'], function (window, $) {
    'use strict';

    var activeClass = 'active';

    function createTabBarTemplate(tabBarClass) {
        return "<ul class='" + tabBarClass + "'></ul>";
    }

    /* Create a <ul> tab bar to hold tabs and insert it into the
     * DOM.
     */
    function createTabBar(tabBarClass, $tabPanes) {
        var $tabBar = $(createTabBarTemplate(tabBarClass));
        // add class so we can correctly size the tab items equal width
        $tabBar.addClass('tabs-' + $tabPanes.length);
        $tabPanes.eq(0).before($tabBar);
        return $tabBar;
    }

    /* Create a tab link with js behaviour for the provided $facet. */
    function createTabItem(tabTitleSelector, $itemTabPane, $tabBar, $tabPanes, tabItemFactory, tabCallback) {
        var tabTitle = "";
        var $tabHeader = $itemTabPane.find(tabTitleSelector + ':first');
        if ($tabHeader.length) {
            tabTitle = $tabHeader.data('tabname') || $tabHeader.text();
        }
        var $tabItem = $("<li></li>").append(tabItemFactory(tabTitle));
        // We want both the li, and the contained a bound to so iOS behaves
        var $tabSelector = $tabItem.add($tabItem.find('a'));
        $tabSelector.on('mousedown touchstart', function(e) {
            $tabBar.find('li.active').removeClass(activeClass);
            $tabItem.addClass(activeClass);
            $tabPanes.removeClass(activeClass);
            $itemTabPane.addClass(activeClass);
            // Trigger lazy loading of any images revealed by switching tabs using the global manager
            window._slm.triggerListeners();
            if (tabCallback) {
                tabCallback($itemTabPane);
            }
            // Stop all the things!
            e.preventDefault();
            e.stopPropagation();
            return false;
        });
        // Block the standard click event completely to stop server hits
        $tabItem.find('a').on('click', function(e) {
            e.preventDefault();
            return false;
        });

        return $tabItem;
    }

    /*
     * Set up a tabBar. Titles for tabs can be overriden by adding 'tabname' data attribute on an item.
     * tabBarClass - CSS class to give the tab bar ul for styling
     * tabSelector - a CSS selector to select each tab element
     * tabTitleSelector - A CSS selector identify the tab title within a tab.
     * maximumTabNumber - The maximum number of tabs this bar supports.
     * maximumTabSets - The maximum number of tabs sets to generate (null for no limit)
     */
    function setupTabs(options) {
        options = options || {};
        options = $.extend({
            tabBarClass: 'tabbar',
            tabSelector: 'div.facet',
            containerSelector: 'div.row',
            tabTitleSelector: 'h2',
            maximumTabNumber: 3,
            maximumTabSets: null,
            tabItemFactory: function(title) {
                return $('<div class="tabbar_label"><a href="#">' + title + '</a></div>');
            },
            tabCallback: undefined
        }, options);
        var tabBarClass = options.tabBarClass;
        var tabSelector = options.tabSelector;
        var tabTitleSelector = options.tabTitleSelector;
        var maximumTabNumber = options.maximumTabNumber;
        var maximumTabSets = options.maximumTabSets;
        var containerSelector = options.containerSelector;
        var numTabSets = 0;
        var hasSetMaximumTabSets = maximumTabSets > 0;

        var initTabBar = function($els) {
            if ($els.length > 1) {
                // Make sure we haven't exceeded the maximum number of tabsets if set
                if (hasSetMaximumTabSets && numTabSets >= maximumTabSets) {
                    return;
                }
                // We have enough facets for tabs
                var $tabBar = createTabBar(tabBarClass, $els);
                var tabItems = [];
                $els.each(function (index, element) {
                    var $element = $(element);
                    var $tabItem = createTabItem(tabTitleSelector, $element,
                        $tabBar, $els,
                        options.tabItemFactory, options.tabCallback);

                    tabItems.push($tabItem);
                    $(element).addClass('tab_content'); // to hide when not active
                    if (index === 0) {
                        /// make the first link active
                        $tabItem.addClass(activeClass);
                        $element.addClass(activeClass);
                    }
                });

                $tabBar.append(tabItems);
                numTabSets++;
            } else {
                // we only have one facet so make it visible at all times.
                $els.addClass(activeClass);
            }
        };

        $(containerSelector).each(function(){
            var $tabPanes = $(),
                $tabItems = $(this).find(tabSelector).siblings().andSelf();

            $.each($tabItems, function () {
                var $this = $(this);
                if (!$this.is(tabSelector) || $tabPanes.length >= maximumTabNumber) {

                    initTabBar($tabPanes);
                    $tabPanes = $();
                }
                if ($this.is(tabSelector)) {
                    $tabPanes = $tabPanes.add($this);
                }
            });

            initTabBar($tabPanes);
        });

    }

    return {
        'setupTabs': setupTabs
    };
});

