define('listen',["gusto-core", "localise", "localise_light", "jquery", "devices", "app_download", "playOverlay", "settings", "window", "domready!"],
    function (gusto, localise, localise_light, $, devices, app_download, playOverlay, settings, window) {
    "use strict";

    var listen = {
        $player_button: $('#player_button'),
        $player_button_twin: $('#player_button_twin'),
        $player_buttons: $('#player_button, #player_button_twin, #schedule_player_button, #chart_player_button, '
            + '.js_show_only_player_button, .player_button, .play-overlay__player-link'),
        openGlobalPlayer: false,

        init: function () {
            // Removes the inline event listener from header_main and header_twin_main
            if (this.$player_button[0]) {
                this.$player_button[0].removeEventListener('click', gusto.temp_player_launch.open);
            }
            if (this.$player_button_twin[0]) {
                this.$player_button_twin[0].removeEventListener('click', gusto.temp_player_launch.open);
            }

            this.attach_click_event(this.$player_buttons);
        },

        playbutton_url: function (url) {
            if (typeof url !== 'undefined') {
                this.$player_button.attr('href', url);
            }
            else if (this.openGlobalPlayer == true) {
                url = gusto.PLAYER_INFO.player_url;
                this.$player_button.attr('href', url);
            } else {
                url = gusto.PLAYER_INFO.player_url_ukrp;
                this.$player_button.attr('href', url);
            }
            return url;
        },

        attach_click_event: function ($el) {
            var that = this;
            $el.on('click', function () {
                if (gusto.PLAYER_INFO.play_overlay === true && playOverlay.isEnabled() && $(this).hasClass('player_button--header')) {
                    playOverlay.open();
                    return false;
                }

                // cookie contains a string to pass in the streaming request
                if ($(this).hasClass('ukrp')) {
                    that.openGlobalPlayer = false;
                }
                else if(settings.default_audio_player) {
                    that.openGlobalPlayer = false;
                }
                else {
                    that.openGlobalPlayer = true;
                }

                var not_overlay_related = (
                    $(this).attr('id') !== 'player_button_twin_overlay'
                    && !$(this).hasClass('play-overlay__player-link')
                );
                if (devices.is_mobile() && !settings.always_launch_ukrp && not_overlay_related) {
                    // non-twin play overlay
                    app_download.showAnnouncement(that.launch_ukrp.bind(that));
                } else {
                    if ($(this).hasClass('ukrp')) {
                        if (gusto.PLAYER_INFO.localisation_light_enabled) {
                            that.launch_ukrp();
                        } else {
                            var href = this.attributes.href.value;
                            that.launch_ukrp(undefined, undefined, href);
                        }
                    }
                    else if(settings.default_audio_player) {
                        var url = this.attributes.href.value;
                        window.open(url, 'audioPlayerWin', 'width=380,height=665,status=0,resizable=1');
                    }
                    else {
                        that.launch_ukrp($(this).data('twin-station'));
                    }
                }
                return false;
            });
        },

        get_gp_player_url: function (localised_slug, twin_station) {
            if (gusto.PLAYER_INFO.global_player_enabled) {
                var player = gusto.PLAYER_INFO.ukrp_endpoints.filter(function (ukrp_endpoint) {
                    if (twin_station) {
                        var endpoints = gusto.PLAYER_INFO.twin_stations[twin_station].endpoints;
                        return ukrp_endpoint.slug === localised_slug && endpoints.indexOf(ukrp_endpoint.streaming_endpoint) !== -1;
                    }
                    return ukrp_endpoint.slug === localised_slug;
                })[0];
                return player.global_player_url;
            }
        },

        launch_ukrp: function (twin_station, global_player_url, ukrp_url) {
            if (gusto.PLAYER_INFO.is_localised) {
                if (gusto.PLAYER_INFO.global_player_enabled && this.openGlobalPlayer) {
                    if (gusto.PLAYER_INFO.localised_slug) {
                        var url = this.playbutton_url(this.get_gp_player_url(gusto.PLAYER_INFO.localised_slug, twin_station));
                    } else {
                        var url = this.playbutton_url(global_player_url);
                    }
                    window.open(url);
                } else {
                    var url = this.playbutton_url(ukrp_url);
                    window.open(url, 'playerWin', 'width=380,height=665,status=0,resizable=1');
                }
            }
            else if (gusto.PLAYER_INFO.localisation_light_enabled) {
                localise_light.show_modal(twin_station, this.launch_ukrp.bind(this, twin_station));
            }
            else {
                localise.show_modal(this.launch_ukrp.bind(this, twin_station));
            }
        }
    };

    listen.init();
    return listen;
});

