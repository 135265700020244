define('gusto/js/app/gusto.live-article',["jquery", "underscore", "video_facade", "slick", "gusto-core", "domready!"], function($, _, videoFacade) {
    'use strict';

    var liveArticle = {
        tenantKey: $('.livecenter-container').data('tenant-key'),
        isLiveArticle: $('.livecenter-container').length > 0,
        lastChangesId: $('.livecenter-container').data('last-change-id'),
        hasMore: $('.livecenter-container').data('initial-load-more') === 'True',
        loadMoreButtonClass: '.live-article-button--load-more',
        updateIntervalMs: 30000,
        articleId: $('.livecenter-container').data('article-id'),
        allEarlierPosts: [],
        allAmendedPosts: [],
        allNewPosts: [],
        hasCustomComponents: $('ncpost-content').length,
        postTemplate: _.template(
            "<section class='live-article-post <%= isPinnedClass %>' data-post-id=<%= id %> data-timestamp=<%= created %>><aside class='live-article-post__sidebar'><div class='live-article-post__time'><p class='relative-time'></p><time class='published-time'></time></div><div class='live-article-post__tags'><%= breakingTag %><%= exclusiveTag %></div></aside><div class='live-article-post__main  <%= breakingClass %> <%= exclusiveClass %>'><%= pinnedImage %><h3 class='live-article-post__title'><%= title %></h3><div><%= content.html %></div><div><div class='live-article-post__sharing-links'></div><div class='live-article-post__metadata'><p class='post__main__footer__metadata__author'><%= authorName %></p><p class='post__main__footer__metadata__date published-date'></p></div></div></div></section>"
        ),


        initResponsiveDatesAndTimes: function() {
            $('.live-article-post').each(function() {
                var timestamp = $(this).data('timestamp');
                if (!timestamp) return;
                var timestampInMilliseconds = new Date(timestamp * 1000);

                var time = timestampInMilliseconds.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                });
                var date = timestampInMilliseconds.toLocaleDateString([], {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                });
                var relativeTime = liveArticle.getRelativeTime(
                    timestampInMilliseconds
                );

                $(this).find('.published-time').html(time);
                if (relativeTime.includes('d ago')) {
                    $(this).find('.published-date').html(date);
                }
                $(this).find('.relative-time').html(relativeTime);
            });

            liveArticle.startRelativeTimeUpdateLoop($('.live-article-post'));
        },

        startRelativeTimeUpdateLoop: function(posts) {
            setInterval(function() {
                posts.each(function() {
                    var timestamp = $(this).data('timestamp');
                    if (!timestamp) return;
                    timestamp = new Date(timestamp * 1000);

                    var relativeTime = liveArticle.getRelativeTime(timestamp);

                    $(this).find('.relative-time').html(relativeTime);
                });
            }, liveArticle.updateIntervalMs);
        },

        getDateNow: function() {
            // required to mock date in tests
            return new Date();
        },

        getRelativeTime: function(timestamp) {
            var relativeMs = this.getDateNow() - timestamp;
            var minutes = Math.floor(relativeMs / 60000);
            var hours = Math.floor(relativeMs / 3600000);
            var days = Math.floor(relativeMs / 86400000);

            if (minutes < 1) return 'Just now';
            if (minutes < 60) return minutes + 'm ago';
            if (hours < 24) return hours + 'h ago';
            if (days < 14) return days + 'd ago';
            return timestamp.toLocaleDateString([], {
                month: 'short',
                day: '2-digit',
            });
        },

        initReplaceCustomComponents: function() {
            $('ncpost-content').each(function() {
                liveArticle.replaceNcPostsContent(this);
            });
        },

        getRenderer: function(attributes) {
            switch (attributes.type) {
                case 'BILDE':
                case 'GIF':
                    return this.imageRenderer(attributes);
                case 'FB-OEMBED':
                    return this.facebookRenderer(attributes);
                case 'IMAGES':
                    return this.galleryRenderer(attributes);
                case 'INFOGRAM':
                    return this.infogramRenderer(attributes);
                case 'INSTAGRAM-OEMBED':
                    return this.instagramRenderer(attributes);
                case 'TWEET':
                    return this.twitterRenderer(attributes);
                case 'VIDEO':
                    return this.youtubeRenderer(attributes);
                case 'BrightcoveVideo':
                    return this.brightcoveRenderer(attributes);
                default:
                    return null;
            }
        },

        replaceNcPostsContent: function(element) {
            var attributes = $(element).data();
            var newElement = this.getRenderer(attributes);

            $(element).replaceWith(newElement);
        },

        facebookRenderer: function(attributes) {
            var wrapper = $('<div></div>');

            wrapper.html(attributes.html);

            return wrapper.get(0);
        },

        galleryRenderer: function(attributes) {
            var wrapper = $("<div></div>");
            var innerContainer = $("<div class='gallery-container'></div>");
            var dotsContainer = $("<div class='dots-container'></div>");
            var arrowsContainer = $("<div class='arrows-container'></div>");
            var nextButton = $(
                "<button class='scrollable__next' style='margin: 0 0 10px 10px'></button>"
            );
            var prevButton = $(
                "<button class='scrollable__prev' style='margin: 0 0 10px 10px'></button>"
            );

            var maxSlidesWidthDesktop = '425px';
            var slidesWidth = window.width < gusto.breakpoints.limits.medium[1] ? '100%' : maxSlidesWidthDesktop;
            wrapper.css({
                position: 'relative',
                'max-width': slidesWidth,
                display: 'block',
                overflow: 'hidden',
            });

            var imagesData = attributes.images;

            imagesData.forEach(function(imageData) {
                var imageContainer = $('<div></div>');
                var imageElement = $('<img></img>');
                imageElement
                    .attr('src', imageData.src)
                    .attr('alt', attributes.caption || '');

                imageContainer.append(imageElement);
                innerContainer.append(imageContainer);
            });

            wrapper.append(innerContainer);
            arrowsContainer.append(prevButton);
            arrowsContainer.append(nextButton);
            wrapper.append(arrowsContainer);
            wrapper.append(dotsContainer);

            function initSlick() {
                $('.gallery-container').each(function() {
                    var $galleryContainer = $(this);

                    if (!$galleryContainer.hasClass('slick-initialized')) {
                        $galleryContainer.slick({
                            infinite: true,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true,
                            dotsClass: 'scrollable__dots',
                            appendDots: dotsContainer,
                            prevArrow: $('.scrollable__prev'),
                            nextArrow: $('.scrollable__next'),
                            appendArrows: arrowsContainer,
                        });
                    }
                });
            }

            function checkAndInit() {
                if ($('.gallery-container').length) {
                    initSlick();
                    clearInterval(intervalId);
                }
            }
            var intervalId = window.setInterval(checkAndInit, 100);
            return wrapper.get(0);
        },

        infogramRenderer: function(attributes) {
            if (
                !$(
                    'script[src="https://e.infogram.com/js/dist/embed-loader-min.js"]'
                ).length
            ) {
                var infogramScript = document.createElement('script');
                infogramScript.text =
                    "!function(e,i,n,s){var t='InfogramEmbeds',d=e.getElementsByTagName('script')[0];if(window[t]&&window[t].initialized)window[t].process&&window[t].process();else if(!e.getElementById(n)){var o=e.createElement('script');o.async=1,o.id=n,o.src='https://e.infogram.com/js/dist/embed-loader-min.js',d.parentNode.insertBefore(o,d)}}(document,0,'infogram-async');";
                document.head.appendChild(infogramScript);
            }

            var wrapper = $('<div></div>');

            wrapper
                .addClass('infogram-embed')
                .attr('data-id', attributes.id)
                .attr('data-type', 'interactive');

            return wrapper.get(0);
        },

        instagramRenderer: function(attributes) {
            var wrapper = $('<div></div>').html(attributes.html);
            return wrapper.get(0);
        },

        imageRenderer: function(attributes) {
            var wrapper = $('<figure></figure>');
            var innerContainer = $('<picture></picture>');
            var image = $('<img></img>');
            var caption = $('<figcaption></figcaption>');
            var attribution = $('<span></span>');
            var link = $('<a></a>');

            image.addClass('content-image');
            image.attr('loading', 'lazy');
            image.attr('height', attributes.height);
            image.attr('width', attributes.width);
            image.css('height', 'auto');
            image.attr('src', attributes.src);
            image.attr('alt', attributes.caption ? attributes.caption : '');
            caption.text(image.attr('alt'));
            attribution.addClass('attribution');
            attribution.text(attributes.source ? ' Picture: ' : '');
            link.text(attributes.source ? attributes.source : '');
            link.attr('href', attributes.url ? attributes.url : '');

            attribution.append(link);
            caption.append(attribution);
            innerContainer.append(image);
            wrapper.append(innerContainer).append(caption);

            return wrapper.get(0);
        },

        twitterRenderer: function(attributes) {
            if (
                !$('script[src="https://platform.twitter.com/widgets.js"]')
                    .length
            ) {
                var twitterScript = document.createElement('script');
                twitterScript.text =
                    "window.twttr = (function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0],t = window.twttr || {};if (d.getElementById(id)) return t;js = d.createElement(s);js.id = id;js.src = 'https://platform.twitter.com/widgets.js';fjs.parentNode.insertBefore(js, fjs);t._e = [];t.ready = function(f) {  t._e.push(f);};return t;}(document, 'script', 'twitter-wjs'));";
                document.head.appendChild(twitterScript);
            }

            var wrapper = $('<div></div>');

            window.twttr.ready(function() {
                window.twttr.widgets.createTweet(
                    attributes.src,
                    wrapper.get(0),
                    {
                        theme: 'light',
                        align: 'left',
                    }
                );
            });

            return wrapper.get(0);
        },

        youtubeRenderer: function(attributes) {
            var wrapper = $('<div></div>');
            var iframe = $('<iframe></iframe>');

            wrapper.addClass('video-container');
            iframe.addClass('js_youtube_embed');
            iframe.attr(
                'src',
                'https://www.youtube.com/embed/' + attributes.src
            );
            iframe.attr('title', 'YouTube video player');
            iframe.attr('frameborder', '0');
            iframe.attr(
                'allow',
                'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            );

            iframe.attr('allowfullscreen', true);
            wrapper.append(iframe);

            return wrapper.get(0);
        },

        brightcoveRenderer: function(attributes) {
            var wrapper = $('<div class="video-container"></div>');
            var button = $('<button class="play-btn" aria-label="Play video"><svg width="100" height="100" viewBox="0 0 100 100" fill="none"><circle cx="50" cy="50" r="50" fill="black" fill-opacity="45%"></circle><path d="M64 49.5L43 62.9234V36.0766L64 49.5Z" fill="white"></path></svg></button>');

            var facade = $('<div></div>');
            facade.addClass('video-facade video-facade__brightcove');
            facade.attr('id', 'video_facade_brightcove_' + attributes.videoId);
            facade.attr('data-video-provider', 'brightcove');
            facade.attr('data-video-id', attributes.videoId);
            facade.attr('data-account', attributes.accountId);

            var image = $('<img />');
            image.attr('src', attributes.poster);
            image.attr('loading', 'lazy');

            facade.append(image)
            facade.append(button)
            wrapper.append(facade)

            return wrapper;
        },

        createPostTemplates: function(postsToParse) {
            var htmlToAppend = [];

            postsToParse.forEach(function(post) {
                var isPinnedClass = post.isPinned ? 'live-article-post--is-pinned' : '';
                var pinnedImage = post.isPinned ? '<img src="https://capital.dev.digital.global.com/assets_v4r/gusto/img/live-article/pin.svg" class="live-article-post__pin" alt="Pinned Post Indicator."/>' : '';

                var exclusiveTag = post.tags && post.tags.some(function(tag) {
                    return tag === 'Exclusive';
                }) ? "<p class='live-article-post__tag live-article-post__tag--exclusive'>Exclusive</p>" : "";

                var breakingTag = post.tags && post.tags.some(function(tag) {
                    return tag === 'Breaking';
                }) ? "<p class='live-article-post__tag live-article-post__tag--breaking'>Breaking</p>" : "";

                var postHTML = liveArticle.postTemplate({
                    isPinnedClass: isPinnedClass,
                    id: post.id,
                    created: post.created,
                    pinnedImage: pinnedImage,
                    breakingClass: post.breaking ? '--breaking' : '',
                    exclusiveClass: post.exclusive ? '--exclusive' : '',
                    exclusiveTag: exclusiveTag,
                    breakingTag: breakingTag,
                    title: post.title,
                    content: post.content,
                    authorName: post.authorName,
                });

                htmlToAppend.push(postHTML);
            });

            return htmlToAppend;
        },

        injectLoadMoreButton: function() {
            $(liveArticle.loadMoreButtonClass).remove();
            $('<button class="live-article-button live-article-button--load-more">Load more</button>')
                .appendTo('.livecenter-container')
                .on('click', liveArticle.injectEarlierPosts);
        },

        injectNewUpdatesButton: function() {
            if ($('.live-article-button--updates')) {
                $('.live-article-button--updates').remove();
            }
            var numNewUpdates = liveArticle.allNewPosts.length;
            if (numNewUpdates > 0) {
                $('<button class="live-article-button live-article-button--updates"></button>')
                    .text(numNewUpdates + ' new updates')
                    .appendTo('.livecenter-container')
                    .on('click', liveArticle.injectNewPosts);
            }
        },


        injectNewPosts: function() {
            $('.live-article-button--updates').remove();
            var pinnedPost = $('.live-article-post--is-pinned');

            liveArticle.allNewPosts.sort(function(postA, postB) {
                var timestampA = parseInt($(postA).attr('data-timestamp'), 10);
                var timestampB = parseInt($(postB).attr('data-timestamp'), 10);
                var isPinnedA = $(postA).hasClass('live-article-post--is-pinned');
                var isPinnedB = $(postB).hasClass('live-article-post--is-pinned');

                if (isPinnedA && !isPinnedB) {
                    return -1;
                } else if (!isPinnedA && isPinnedB) {
                    return 1;
                } else {
                    return timestampB - timestampA;
                }
            });

            var pinnedPosts = [];
            var nonPinnedPosts = [];
            var hasLiveSummary = $('.live-article-summary').length > 0;

            for (var i = 0; i < liveArticle.allNewPosts.length; i++) {
                var post = liveArticle.allNewPosts[i];
                var isPinned = $(post).hasClass('live-article-post--is-pinned');

                if (isPinned) {
                    pinnedPosts.push(post);
                } else {
                    nonPinnedPosts.push(post);
                }
            }

            if (pinnedPost.length > 0) {
                pinnedPost.last().after(nonPinnedPosts);
            } else {
                if (hasLiveSummary) {
                    $('.live-article-summary').after(nonPinnedPosts);
                } else {
                    $('.live-article-post').first().before(nonPinnedPosts);
                }
            }

            if (pinnedPost.length > 0) {
                for (var j = pinnedPosts.length - 1; j >= 0; j--) {
                    $(pinnedPost.first()).before(pinnedPosts[j]);
                }
            } else {
                if (hasLiveSummary) {
                    $('.live-article-summary').after(pinnedPosts);
                } else {
                    $('.live-article-post').first().before(pinnedPosts);
                }
            }

            liveArticle.initReplaceCustomComponents();
            liveArticle.initResponsiveDatesAndTimes();
            videoFacade.init();

            var topNewPost = liveArticle.allNewPosts
                .map(function(post) {
                    return $('[data-post-id="' + $(post).data('post-id') + '"]').first();
                })
                .filter(function(postElement) {
                    return postElement.length > 0;
                })
                .sort(function(a, b) {
                    return $(a).offset().top - $(b).offset().top;
                })[0];

            if (topNewPost) {
                var newPostTop = $(topNewPost).offset().top;
                $('html, body').animate({
                    scrollTop: newPostTop - 100,
                }, 500);
            }
            liveArticle.allNewPosts = [];
        },

        injectEarlierPosts: function() {
            var totalPosts = liveArticle.allEarlierPosts.length;
            var postsInjected = 0;
            var remainingPosts = totalPosts - postsInjected;
            var postsToInject = Math.min(10, remainingPosts);
            for (var i = postsInjected; i < postsInjected + postsToInject; i++) {
                if ($(liveArticle.loadMoreButtonClass).length > 0) {
                    $(liveArticle.loadMoreButtonClass).remove();
                }

                var postToInject = liveArticle.allEarlierPosts.shift();

                $('.livecenter-container').append(postToInject);

                if (remainingPosts > 0) {
                    liveArticle.injectLoadMoreButton();
                }
            }

            postsInjected += postsToInject;
            liveArticle.initReplaceCustomComponents();
            liveArticle.initResponsiveDatesAndTimes();
            videoFacade.init();

            if (postsInjected >= totalPosts) {
                liveArticle.fetchOlderPosts();
                if (postsInjected >= totalPosts) {
                    $(liveArticle.loadMoreButtonClass).remove();
                }
            }
        },

        fetchOlderPosts: function() {
            var latestPostLoadedId = $('.live-article-post').last().data('post-id');
            return fetch(
                'https://livecentercdn.norkon.net/BulletinFeed/' + liveArticle.tenantKey + '/' + liveArticle.articleId + '/EarlierObj/' + latestPostLoadedId + '/')
                .then(function(response) { return response.json(); })
                .then(function(data) {
                    if (data.result.bulletins.length > 0) {
                        liveArticle.injectLoadMoreButton();
                    }
                    return liveArticle.createPostTemplates(data.result.bulletins);
                })
                .then(function(parsedHTML) {
                    liveArticle.allEarlierPosts = parsedHTML;
                })
                .catch(function(error) {
                    console.error('Error fetching JSON:', error);
                    return null;
                });
        },

        postExists: function(postId, postArray) {
            return postArray.some(function(post) {
                var regex = new RegExp('data-post-id=' + postId, 'g');
                return regex.test(post);
            });
        },

        pollForNewPosts: function() {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow',
            };

            return fetch('https://livecentercdn.norkon.net/BulletinFeed/' + liveArticle.tenantKey + '/' + liveArticle.articleId + '/Changes/' + liveArticle.lastChangesId + '/', requestOptions)
                .then(function(response) {
                    return response.json();
                })
                .then(function(data) {
                    if (data.result.initial) {
                        data.result.initial.addedOrChanged.forEach(function(post) {
                            if (!post.content.html) return;
                            post.isPinned = data.result.initial.inferred && data.result.initial.inferred.hasOwnProperty(post.id);
                            var postId = post.id;
                            var postExistsInNewPosts = liveArticle.postExists(postId, liveArticle.allNewPosts);
                            var postExistsInDocument = $('[data-post-id="' + postId + '"]').length > 0;
                            var postExistsInEarlierPosts = liveArticle.postExists(postId, liveArticle.allEarlierPosts);

                            if (!postExistsInNewPosts && !postExistsInDocument && !postExistsInEarlierPosts) {
                                var postHtml = liveArticle.createPostTemplates([post]);
                                liveArticle.allNewPosts = liveArticle.allNewPosts.concat(postHtml);
                                liveArticle.injectNewUpdatesButton();
                            }
                        });
                    } else {
                        liveArticle.lastChangesId = data.result.lastChangesId;
                    }
                    if (data.result.addedOrChanged) {
                        data.result.addedOrChanged.forEach(function(post) {
                            if (!post.content.html) return;
                            post.isPinned = data.result.pinnedPostIds && data.result.pinnedPostIds.includes(post.id);
                            var postId = post.id;
                            var postExistsInEarlierPosts = liveArticle.postExists(postId, liveArticle.allEarlierPosts);
                            var postExistsInTheDocument = $('[data-post-id="' + postId + '"]').length > 0;
                            var postExistsInAddedPosts = liveArticle.postExists(postId, liveArticle.allNewPosts);
                            var existingPostElement = postExistsInEarlierPosts || postExistsInTheDocument || postExistsInAddedPosts;

                            if (existingPostElement) {
                                liveArticle.allAmendedPosts = liveArticle.allAmendedPosts.concat(liveArticle.createPostTemplates([post]));
                            } else {
                                liveArticle.allNewPosts = liveArticle.allNewPosts.concat(liveArticle.createPostTemplates([post]));
                                liveArticle.injectNewUpdatesButton();
                            }
                        });
                    }
                })
                .catch(function(error) {
                    console.error('Error fetching JSON:', error);
                    return null;
                });
        },
    };

    if (liveArticle.hasCustomComponents)
        liveArticle.initReplaceCustomComponents();
    if (liveArticle.isLiveArticle) {
        liveArticle.initResponsiveDatesAndTimes();
        videoFacade.init();
        setInterval(function() {
            liveArticle.pollForNewPosts();
        }, liveArticle.updateIntervalMs);
        if (liveArticle.hasMore) {
            liveArticle.injectLoadMoreButton();
            liveArticle.fetchOlderPosts();
        }
    }
    return liveArticle;
});

