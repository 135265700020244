define('accordion',['window', 'jquery', 'tabs-setup'], function (window, $) {
    "use strict";

    var accordion = {
        $accordions: $('.cardset.feature.tab_content'),
        hidden_class: 'accordion-hidden',
        init: function () {
            var that = this;

            this.reset_accordion();
            this.create_pseudo_headers();
            $(this.$accordions[0]).removeClass(this.hidden_class);

            $('.cardset__title', this.$accordions).on('click', function () {
                that.reset_accordion();
                $(this).parent().removeClass(that.hidden_class);
                return false;
            });

            this.$accordions.children('h2, .accordion_psuedoheader').wrapInner('<a href="#"></a>');
        },

        create_pseudo_headers: function () {
            var that = this;
            $.each(that.$accordions, function() {
                if ($(this).children('h2').length === 0) {
                    $(this).prepend('<div class="accordion_psuedoheader"></div>');
                }
            });
        },

        reset_accordion: function () {
            this.$accordions.addClass(this.hidden_class);
        }
    };

    accordion.init();

    return accordion;
});

