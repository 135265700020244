define('gusto/js/app/gusto.hashlinks',['jquery', 'devices', '!domready'], function ($, devices){
    "use strict";

    var hashlink = {
        header_height: $('.js-header-main').height(),
        init: function() {
            var that = this;
            if (devices.is_mobile()) {
                window.onhashchange = function () {
                    var $hash = $(location.hash);
                    if ($hash.length) {
                        var new_top = $hash.offset().top - that.header_height;
                        window.scrollTo(0, new_top);
                    }
                };
            }
            return this;
        }
    };
    $(document).ready(function() {
        hashlink.init();
    });
});

