define('localisation-cookie',["window", "gusto-core", "cookies", "domready!"], function (window, gusto, cookies) {
    "use strict";

    /*
        SETTING SESSION_STATION
        =======================

        POSSIBLE TRIP UPS
        -----------------
        Don't assume the path always ends in /

        LOGIC
        -----
        IF we are on a localisation root
        OR ( we are on a localisation path AND we don't have a session )
            SET _session cookie_ to gusto.STATION
        ELSE IF ( we have a _preferred station cookie_ AND we don't have a session )
            SET _session cookie_ to preferred station cookie
        ELSE
            DO NOTHING

        DATA NEEDED
        -----------
        full_path
        gusto.ABSOLUTE_PATH (no localisation data)
        gusto.STATION
        preferred station cookie
        session station cookie
    */
    var localisationCookie = {
        init: function () {
            var isUnlocalisedHomepage = window.location.pathname === '/';
            var absoluteUrlStrippedLocalisation = gusto.ABSOLUTE_URL;

            var isLocalisationRoot = false;
            var isPathLocalised = false;

            // if it is the homepage we know it is not a localised url
            if (!isUnlocalisedHomepage) {
                var isLocalisationRoot = this.isPathLocalisationRoot(window.location.pathname, absoluteUrlStrippedLocalisation);
                var isPathLocalised = this.isPathLocalised(window.location.pathname, absoluteUrlStrippedLocalisation);
            }

            // get the existing cookie data
            var cookiePreferredStation = cookies.readCookie('station');
            var cookieSessionStation = cookies.readCookie('session_station');

            // if we are on the the root of a localisation OR the path is localised and there is no Session Cookie
            if (isLocalisationRoot || (isPathLocalised && cookieSessionStation === null)) {
                cookies.createCookie('session_station', gusto.STATION.slug);
            }
            // if there is a user cookie and there is no session cookie
            else if (cookiePreferredStation && cookieSessionStation === null) {
                cookies.createCookie('session_station', cookiePreferredStation);
            }
        },

        setSessionCookie: function (newSessionStation) {
            cookies.createCookie('session_station', newSessionStation);
        },

        isPathLocalisationRoot: function(fullPath, absoluteUrlStrippedLocalisation) {
            return absoluteUrlStrippedLocalisation === '/';
        },

        isPathLocalised: function(fullPath, absoluteUrlStrippedLocalisation) {
            var rootOfFullPath = fullPath.split('/')[1];
            var rootOfAbsolutePath = absoluteUrlStrippedLocalisation.split('/')[1];

            // this will occur when going to an unlocalised page that isn't the homepage
            return rootOfFullPath !== rootOfAbsolutePath;
        },

        getLocalisedPath: function() {
            // Note: Do not send window.location.pathname directly, it can enable XSS attacks
            if (window.location.pathname === '/') {
                return '/';
            }
            else if (gusto.STATION.slug && !this.isPathLocalised(window.location.pathname, gusto.ABSOLUTE_URL)) {
                return '/' + gusto.STATION.slug + gusto.ABSOLUTE_URL;
            }
            else {
                return gusto.ABSOLUTE_URL;
            }
        },
    };

    localisationCookie.init();
    gusto.localisation = localisationCookie;
    return localisationCookie;
});

