define('base_settings',[],function () {
    "use strict";

    var settings = {
        lazy_load: {
            threshold: 500,
        },
        always_launch_ukrp: false,
        default_audio_player: false,
        font_faces: ['Open Sans'],
    };

    return settings;
});

