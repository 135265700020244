define('tabs-setup',['tabs'], function (tabs) {
    'use strict';

    function init () {
        // Non-featured
    	tabs.setupTabs({
    		tabSelector: 'div.facet:not(.feature)'
    	});

        // Level 2 Featured
        tabs.setupTabs({
        	tabBarClass: 'tabbar feature feature2',
        	tabSelector: 'div.facet.feature2',
        	maximumTabSets: 1
        });

        // Level 3 Featured
        tabs.setupTabs({
            tabBarClass: 'tabbar feature feature3',
            tabSelector: 'div.facet.feature3',
            maximumTabSets: 1
        });
    }

    init();
});

