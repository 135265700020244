define('gusto/js/app/gusto.timestamps',["jquery",  "domready!"], function($) {
    'use strict';

    var timestamps = {
        $timestamps : $('.promo p.publish_date'),
        now : Date.now(),
        ms_in_minute: 60000,
        ms_in_hour: 3600000,
        ms_in_day: 86400000,
        ms_in_month: 2592000000,

        init : function () {
            var that = this;
            this.$timestamps.each(function(){
                var $this = $(this);
                $this.html(that.get_date_format($this.data('timestamp')));
            });
        },

        get_date_format : function (timestamp) {
            if (!timestamp) return;
            var time_since_published = this.now - timestamp;
            var mins_ago = Math.floor(this.get_minutes(time_since_published));
            var hours_ago = Math.floor(this.get_hours(time_since_published));
            var days_ago = Math.floor(this.get_days(time_since_published));
            var months_ago = Math.floor(this.get_months(time_since_published));

            if (mins_ago < 1) {
                return "Just now";
            }

            if (mins_ago < 60) {
                return mins_ago + " min" + ((mins_ago > 1) ? "s" : "") +  " ago";
            }

            if (hours_ago < 24) {
                return hours_ago + " hour" + ((hours_ago > 1) ? "s" : "") +  " ago";
            }

            if (days_ago < 30) {
                return days_ago + " day" + ((days_ago > 1) ? "s" : "") +  " ago";
            }

            if (months_ago < 12) {
                return months_ago + " month" + ((months_ago > 1) ? "s" : "") +  " ago";
            }

            return "Ages ago";
        },

        get_minutes : function (milliseconds) {
            return milliseconds / this.ms_in_minute;
        },

        get_hours : function (milliseconds) {
            return milliseconds / this.ms_in_hour;
        },

        get_days : function (milliseconds) {
            return milliseconds / this.ms_in_day;
        },

        get_months : function (milliseconds) {
            return milliseconds / this.ms_in_month;
        }
    };

    timestamps.init();
    return timestamps;
});

